import axios from "axios";
import * as AuthLib from "./auth";
import * as SweetAlertConnector from "./SweetAlertConnector"

const api_url = "/api/";
// const api_url = "https://gy.mawionline.com/api/";
// const api_url = "https://tt.mawionline.com/api/";
// const api_url = "https://mawi-fastapi-staging.wonderfulflower-f4234c00.eastus.azurecontainerapps.io/";
// const api_url = "http://127.0.0.1:8000/";
// const api_url = "https://mawi-us-demo.wonderfulflower-f4234c00.eastus.azurecontainerapps.io/api/";

const withTokenCheck = (requestFunc) => async (...args) => {
    try {
        const isTokenValid = AuthLib.isAuthenticated(); // Call your existing function to check token validity

        if (!isTokenValid) {
            // Redirect to login or refresh token logic
            SweetAlertConnector.showErrorAlert("Session Expired", "Please login again to continue.");
            return;
        }
        console.log("Token is valid, continuing with request...");

        // Execute the original request function
        return await requestFunc(...args);
    } catch (error) {
        console.error("Error during request:", error.message);
        throw error; // You may rethrow to handle errors elsewhere
    }
};

export const loginUser = async (username, password) => {
    try {
        // Prepare the data for the request
        const data = new URLSearchParams();
        data.append("username", username);
        data.append("password", password);

        // Make a POST request using axios with custom headers
        const response = await axios({
            method: "POST", // Specify the method explicitly
            url: `token`, // The endpoint URL
            headers: {
                "Content-Type": "application/x-www-form-urlencoded", // Set Content-Type header
            },
            data: data, // The request payload
            maxBodyLength: Infinity, // To handle large payloads if necessary
        });

        // Check if the response status is not OK
        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Extract the access token from the response
        const { access_token, token_type } = response.data;

        console.log("Access Token:", access_token);

        // Store the token in local storage (or any other secure storage)
        localStorage.setItem("accessToken", access_token);
        localStorage.setItem("tokenType", token_type);

        // Return the access token for further use
        return access_token;
    } catch (error) {
        console.error("There was a problem with the login request:", error);
    }
};

export const getCountries = async () => {
    try {
        // Make a GET request using axios with custom headers
        const response = await axios({
            method: "GET", // Specify the method explicitly
            url: `${api_url}countries`, // The endpoint URL
            headers: {
                "Content-Type": "application/json", // Set Content-Type header
                // Add any other headers you might need
            },
        });

        // Check if the response status is not OK
        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = response.data; // Extract the JSON data from the response

        // Process and return the data
        return data.map((country) => ({
            name: country.name,
            code: country.two_letter_code,
            id: country.id,
        }));
    } catch (error) {
        console.error("There was a problem with the request:", error);
    }
};

export const getCountryByID = async (id) => {
    try {
        const response = await axios.get(`${api_url}countries/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const getCountryByTwoLetterCode = async (code) => {
    try {
        const response = await axios.get(`${api_url}countries/code/${code}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const getPackageTypes = async () => {
    try {
        const response = await axios.get(`${api_url}package-types`);
        const data = response.data;
        return data.map((packageType) => ({
            value: packageType.code,
            text: packageType.name,
            id: packageType.id,
        }));
    } catch (error) {
        console.error(error);
    }
};

export const createPackageType = async (data) => {
    try {
        const response = await axios.post(`${api_url}package-types`, data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const getVessels = async () => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}vessels`,
            headers: {
                Authorization: `${tokenType} ${token}`,
                "Content-Type": "application/json",
            },
            maxBodyLength: Infinity,
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = response.data;

        return data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
    }
};

export const addVessel = async (vesselData) => {
    try {
        const token = localStorage.getItem("accessToken"); // Retrieve token from localStorage
        const tokenType = localStorage.getItem("tokenType") || "Bearer";

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "POST",
            url: `${api_url}vessels`, // Ensure the URL is correct
            headers: {
                "Content-Type": "application/json",
                Authorization: `${tokenType} ${token}`, // Retrieve token from local storage
            },
            data: JSON.stringify(vesselData), // Pass the data parameter here
            maxBodyLength: Infinity, // To handle large payloads if necessary
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the response data
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error;
    }
};

export const getVesselByID = async (id) => {
    try {
        const response = await axios.get(`${api_url}vessels/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

// export const updateVessel = async (id, data) => {
//     try {
//         const response = await axios.put(`${api_url}vessels/${id}`, data)
//         return response.data
//     } catch (error) {
//         console.error(error)
//     }
// }

export const deleteVessel = async (id) => {
    try {
        const response = await axios.delete(`${api_url}vessels/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const searchVesselByName = async (name) => {
    try {
        const response = await axios.get(`${api_url}vessels/search/${name}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const getOffices = async () => {
    try {
        const response = await axios({
            method: "GET",
            url: `${api_url}offices`, // Ensure the trailing slash matches the fetch request
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = response.data;

        return data.map((office) => ({
            name: office.name,
            asycudaCode: office.code,
            id: office.id,
            locationOfGoods: office.location_of_goods,
        }));
    } catch (error) {
        console.error("There was a problem with the request:", error);
    }
};

export const getOfficeByID = async (id) => {
    try {
        const response = await axios.get(`${api_url}offices/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const createOffice = async (data) => {
    try {
        const response = await axios.post(`${api_url}offices`, data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const updateOffice = async (id, data) => {
    try {
        const response = await axios.put(`${api_url}offices/${id}`, data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const deleteOffice = async (id) => {
    try {
        const response = await axios.delete(`${api_url}offices/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const getRegimeTypes = async () => {
    try {
        const response = await axios({
            method: "GET",
            url: `${api_url}regime-types`, // Ensure the trailing slash matches the fetch request
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = response.data;

        return data.map((regimeType) => ({
            regimeType: `${regimeType.code} - ${regimeType.name}`,
            regimeTypeCode: regimeType.code,
            regimeTypeId: regimeType.id,
            cpcCodes: regimeType.cpc_codes.map((cpc) => ({
                code: cpc.code,
                name: cpc.name,
                cpcId: cpc.id,
                cpcDisplay: `${cpc.code} - ${cpc.name}`,
                npcCodes: cpc.npc_codes.map((npc) => ({
                    npcId: npc.npc_code_id,
                    cpcNpcRelationId: npc.id,
                    code: npc.npc_code.code,
                    name: npc.npc_code.name,
                    npcDisplay: `${npc.npc_code.code} - ${npc.npc_code.name}`,
                })),
            })),
        }));
    } catch (error) {
        console.error("There was a problem with the request:", error);
    }
};

export const createRegimeType = async (data) => {
    try {
        const response = await axios.post(`${api_url}regime-types`, data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const getRegimeTypeByID = async (id) => {
    try {
        const response = await axios.get(`${api_url}regime-types/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const updateRegimeType = async (id, data) => {
    try {
        const response = await axios.put(`${api_url}regime-types/${id}`, data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const deleteRegimeType = async (id) => {
    try {
        const response = await axios.delete(`${api_url}regime-types/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const searchRegimeTypeByName = async (name) => {
    try {
        const response = await axios.get(`${api_url}regime-types/search/${name}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const getTradeAgreements = async () => {
    try {
        const response = await axios({
            method: "GET",
            url: `${api_url}trade-agreements`, // Ensure the trailing slash matches the fetch request
            headers: {
                "Content-Type": "application/json",
            },
        });

        // Check the response status explicitly
        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = response.data;

        // Here, we directly return the data. Adjust this if you need specific formatting.
        return data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally rethrow the error if needed
        throw error;
    }
};

export const getImporters = async () => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}partners/importers`,
            headers: {
                Authorization: `${tokenType} ${token}`,
                "Content-Type": "application/json",
            },
            maxBodyLength: Infinity,
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = response.data;

        return data.map((importer) => ({
            id: importer.id,
            name: importer.partner.name,
            address: importer.partner.address,
            country: importer.partner.country,
            phoneContact: importer.partner.phone_contact,
            taxIdentificationNumber: importer.partner.tax_identification_number,
            partner_id: importer.partner.id,
        }));
    } catch (error) {
        console.error("There was a problem with the request:", error);
    }
};

export const getExporters = async () => {
    try {
        // Retrieve the token from local storage
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        // Make a GET request using axios with the authorization header
        const response = await axios({
            method: "GET", // Specify the method explicitly
            url: `${api_url}partners/exporters`, // The endpoint URL
            headers: {
                Authorization: `${tokenType} ${token}`, // Set Authorization header with the token
                "Content-Type": "application/json", // Set Content-Type header
            },
            maxBodyLength: Infinity, // To handle large payloads if necessary
        });

        // Check if the response status is not OK
        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = response.data; // Extract the JSON data from the response

        // Process and return the data
        return data.map((exporter) => ({
            id: exporter.id,
            name: exporter.partner.name,
            address: exporter.partner.address,
            country: exporter.partner.country,
            phoneContact: exporter.partner.phone_contact,
            taxIdentificationNumber: exporter.partner.tax_identification_number,
            partner_id: exporter.partner.id,
        }));
    } catch (error) {
        console.error("There was a problem with the request:", error);
    }
};

export const getDeclarants = async () => {
    try {
        // Retrieve the token from local storage
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        // Make a GET request using axios with the authorization header
        const response = await axios({
            method: "GET", // Specify the method explicitly
            url: `${api_url}partners/declarants`, // The endpoint URL
            headers: {
                Authorization: `${tokenType} ${token}`, // Set Authorization header with the token
                "Content-Type": "application/json", // Set Content-Type header
            },
            maxBodyLength: Infinity, // To handle large payloads if necessary
        });

        // Check if the response status is not OK
        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = response.data; // Extract the JSON data from the response

        // Process and return the data
        return data.map((declarant) => ({
            id: declarant.id,
            name: declarant.partner.name,
            address: declarant.partner.address,
            country: declarant.partner.country,
            phoneContact: declarant.partner.phone_contact,
            taxIdentificationNumber: declarant.partner.tax_identification_number,
            partner_id: declarant.partner.id,
        }));
    } catch (error) {
        console.error("There was a problem with the request:", error);
    }
};

export const getCurrencies = async () => {
    try {
        const response = await axios({
            method: "GET",
            url: `${api_url}currencies`, // Ensure the trailing slash matches the fetch request
            headers: {
                "Content-Type": "application/json",
            },
            maxBodyLength: Infinity, // To handle large payloads if necessary
        });

        // Check the response status explicitly
        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = response.data;

        // Process and return the data in the desired format
        return data.map((currency) => ({
            id: currency.id,
            code: currency.currency_code,
            name: currency.full_name,
        }));
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
    }
};

export const getIncoTerms = async () => {
    try {
        const response = await axios({
            method: "GET",
            url: `${api_url}delivery-terms`, // Ensure the trailing slash matches the fetch request
            headers: {
                "Content-Type": "application/json",
            },
            maxBodyLength: Infinity, // To handle large payloads if necessary
        });

        // Check the response status explicitly
        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = response.data;

        // Process and return the data in the desired format
        return data.map((deliveryTerm) => ({
            id: deliveryTerm.id,
            code: deliveryTerm.code,
            name: deliveryTerm.name,
        }));
    } catch (error) {
        console.error("There was a problem with the request:", error.message);

        throw error;
    }
};

export const saveWaybill = async (waybillData) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "POST",
            url: `${api_url}waybills`, // Ensure the URL is correct
            headers: {
                "Content-Type": "application/json",
                Authorization: `${tokenType} ${token}`, // Retrieve token from local storage
            },
            data: JSON.stringify(waybillData), // Pass the data parameter here
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                // This includes all 4xx client errors, so we can handle them gracefully
                return status < 500;
            },
        });
        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            console.log(response);
            return response;
        }
        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};

export const getCustomsEntry = async (entryId) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}customs-entries/${entryId}`, // Dynamically include entryId in the URL
            headers: {
                Authorization: `${tokenType} ${token}`, // Retrieve token from local storage
                "Content-Type": "application/json", // Consistent Content-Type
            },
            maxBodyLength: Infinity, // To handle large payloads if necessary
        });

        return response.data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};

export const createCustomsEntry = async (entryData) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "POST",
            url: `${api_url}customs-entries`, // Correct endpoint URL with trailing slash
            headers: {
                "Content-Type": "application/json", // Set Content-Type header
                Accept: "application/json", // Set Accept header for response format
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                // Add any other headers you might need here
            },
            data: JSON.stringify(entryData), // Pass the data parameter as JSON
            maxBodyLength: Infinity, // To handle large payloads if necessary
        });

        // Check if the response status is not OK
        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = response.data;

        return data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};

export const getAllRateOfExchange = async (targetCurrencyId) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}rate-of-exchange/search?target_currency_id=${targetCurrencyId}`, // Endpoint URL for searching rate of exchange
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/json", // Set Accept header for response format
            },
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                // This includes all 4xx client errors, so we can handle them gracefully
                return status < 500;
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response so it can be handled in the calling function
        }

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the data on successful response
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};

export const getRateOfExchange = async (baseCurrencyId, targetCurrencyId, effectiveDate) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}rate-of-exchange/search?base_currency_id=${baseCurrencyId}&target_currency_id=${targetCurrencyId}&effective_date=${encodeURIComponent(
                effectiveDate
            )}`,
            headers: {
                "Content-Type": "application/json", // Ensure Content-Type header is set correctly
                Accept: "application/json", // Ensure Accept header is set correctly
                Authorization: `${tokenType} ${token}`, // Retrieve token from local storage
            },
            maxBodyLength: Infinity, // To handle large payloads if necessary
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};

export const createRateOfExchange = async (exchangeData) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "POST",
            url: `${api_url}rate-of-exchange`, // Endpoint URL for creating rate of exchange
            headers: {
                "Content-Type": "application/json", // Set Content-Type header
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/json", // Set Accept header for response format
            },
            data: JSON.stringify(exchangeData), // Pass the exchange data parameter as JSON
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                // This includes all 4xx client errors, so we can handle them gracefully
                return status < 500;
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response so it can be handled in the calling function
        }
        console.log("Response: ", response);

        return response.data; // Return the data on successful response
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};

export const getSuppliers = async () => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}partners/suppliers`, // Update the URL to point to suppliers endpoint
            headers: {
                Authorization: `${tokenType} ${token}`,
                "Content-Type": "application/json",
            },
            maxBodyLength: Infinity,
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = response.data;

        return data.map((supplier) => ({
            id: supplier.id,
            name: supplier.partner.name,
            address: supplier.partner.address,
            country: supplier.partner.country,
            phoneContact: supplier.partner.phone_contact,
            taxIdentificationNumber: supplier.partner.tax_identification_number,
            partner_id: supplier.partner.id,
        }));
    } catch (error) {
        console.error("There was a problem with the request:", error);
    }
};

export const createCommercialInvoices = async (invoiceData) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "POST",
            url: `${api_url}commercial-invoices`, // Correct endpoint URL with trailing slash
            headers: {
                "Content-Type": "application/json", // Set Content-Type header
                Accept: "application/json", // Set Accept header for response format
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
            },
            data: JSON.stringify(invoiceData), // Pass the invoice data parameter as JSON
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                // This includes all 4xx client errors, so we can handle them gracefully
                return status < 500;
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            console.log(response);
            return response;
        }

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};

export const updateCustomsEntry = async (entryId, entryData) => {
    console.log("Entry Id: ", entryId);
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "PUT",
            url: `${api_url}customs-entries/${entryId}`, // Dynamically set the endpoint URL with the entryId
            headers: {
                "Content-Type": "application/json", // Set Content-Type header
                Accept: "application/json", // Set Accept header for response format
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
            },
            data: JSON.stringify(entryData), // Pass the entry data parameter as JSON
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                // This includes all 4xx client errors, so we can handle them gracefully
                return status < 500;
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response so it can be handled in the calling function
        }

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response; // Return the data on successful response
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};

export const updateWaybill = async (waybillId, waybillData) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "PUT",
            url: `${api_url}waybills/${waybillId}`, // Dynamically set the endpoint URL with the waybillId
            headers: {
                "Content-Type": "application/json", // Set Content-Type header
                Accept: "application/json", // Set Accept header for response format
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
            },
            data: JSON.stringify(waybillData), // Pass the waybill data parameter as JSON
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                // This includes all 4xx client errors, so we can handle them gracefully
                return status < 500;
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response so it can be handled in the calling function
        }

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response; // Return the data on successful response
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};

export const updateCommercialInvoices = async (invoiceData) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "PUT",
            url: `${api_url}commercial-invoices`, // Endpoint URL for updating commercial invoices
            headers: {
                "Content-Type": "application/json", // Set Content-Type header
                Accept: "application/json", // Set Accept header for response format
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
            },
            data: JSON.stringify(invoiceData), // Pass the invoice data parameter as JSON
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                // This includes all 4xx client errors, so we can handle them gracefully
                return status < 500;
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response so it can be handled in the calling function
        }

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response; // Return the data on successful response
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};

export const searchPartners = async (name) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}partners/search`, // Endpoint URL for searching partners
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/json", // Set Accept header for response format
            },
            params: {
                name: name, // Query parameter for partner search
            },
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                // This includes all 4xx client errors, so we can handle them gracefully
                return status < 500;
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response so it can be handled in the calling function
        }

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the data on successful response
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};

export const getSeatingPositions = async () => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}seating-positions`, // Endpoint URL for getting seating positions
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/json", // Set Accept header for response format
            },
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                // This includes all 4xx client errors, so we can handle them gracefully
                return status < 500;
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response so it can be handled in the calling function
        }

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the data on successful response
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};

export const getFuelTypes = async () => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}fuel-types`, // Endpoint URL for getting fuel types
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/json", // Set Accept header for response format
            },
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                // This includes all 4xx client errors, so we can handle them gracefully
                return status < 500;
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response so it can be handled in the calling function
        }

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the data on successful response
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};

export const getVehicleModels = async () => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}vehicle-models`, // Endpoint URL for getting vehicle models
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/json", // Set Accept header for response format
            },
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                // This includes all 4xx client errors, so we can handle them gracefully
                return status < 500;
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response so it can be handled in the calling function
        }

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the data on successful response
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};

export const createVehicle = async (vehicleData) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "POST",
            url: `${api_url}vehicles`, // Endpoint URL for creating a vehicle
            headers: {
                "Content-Type": "application/json", // Set Content-Type header
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/json", // Set Accept header for response format
            },
            data: JSON.stringify(vehicleData), // Pass the vehicle data parameter as JSON
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                // This includes all 4xx client errors, so we can handle them gracefully
                return status < 500;
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response so it can be handled in the calling function
        }

        if (response.status !== 200) {
            // Expecting 201 Created for a successful POST
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the data on successful response
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};

export const generateWorksheetPDF = withTokenCheck(async (customsEntryId, referenceNumber = "", merge_invoices =  false) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}worksheet/generate-worksheet-pdf?customs_entry_id=${customsEntryId}&merge_invoices=${merge_invoices}`, // Endpoint URL for generating worksheet PDF
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/pdf", // Set Accept header for PDF response format
            },
            responseType: "blob", // Ensure the response is treated as a binary file
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                // This includes all 4xx client errors, so we can handle them gracefully
                return status < 500;
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response so it can be handled in the calling function
        }

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Handle the PDF download
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `worksheet_${referenceNumber}.pdf`); // Set the file name
        document.body.appendChild(link);
        link.click();
        link.remove();

        return response.data; // Optionally return the data for further handling
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
});

export const generateScoonerBLPDF = withTokenCheck(async (customsEntryId, referenceNumber = "",) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}generate-scooner-bl-pdf?customs_entry_id=${customsEntryId}`, // Endpoint URL for generating Scooner PDF
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/pdf", // Set Accept header for PDF response format
            },
            responseType: "blob", // Ensure the response is treated as a binary file
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                // This includes all 4xx client errors, so we can handle them gracefully
                return status < 500;
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response so it can be handled in the calling function
        }

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Handle the PDF download
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Scooner-BL-${referenceNumber}.pdf`); // Set the file name
        document.body.appendChild(link);
        link.click();
        link.remove();

        return response.data; // Optionally return the data for further handling
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
});

export const searchCustomsEntries = async (status, limit=60, offset=0) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}customs-entries/search?status=${encodeURIComponent(status)}&limit=${limit}&offset=${offset}`, // Endpoint URL for searching customs entries with status
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/json", // Set Accept header for response format
            },
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                // This includes all 4xx client errors, so we can handle them gracefully
                return status < 500;
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response so it can be handled in the calling function
        }

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the data on successful response
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};

export const updateVessel = async (vesselId, vesselData) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "PUT",
            url: `${api_url}vessels/${vesselId}`, // Endpoint URL for updating a vessel
            headers: {
                "Content-Type": "application/json", // Set Content-Type header
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/json", // Set Accept header for response format
            },
            data: JSON.stringify(vesselData), // Pass the vessel data parameter as JSON
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                // This includes all 4xx client errors, so we can handle them gracefully
                return status < 500;
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response so it can be handled in the calling function
        }

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the data on successful response
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};

export const createPartner = async (partnerData) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "POST",
            url: `${api_url}partners`, // Endpoint URL for creating a new partner
            headers: {
                "Content-Type": "application/json", // Set Content-Type header
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/json", // Set Accept header for response format
            },
            data: JSON.stringify(partnerData), // Pass the partner data parameter as JSON
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                // This includes all 4xx client errors, so we can handle them gracefully
                return status < 500;
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response so it can be handled in the calling function
        }
        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the data on successful response
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};

export const addImporter = async (importerData) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "POST",
            url: `${api_url}partners/importers/`, // Endpoint URL for adding an importer
            headers: {
                "Content-Type": "application/json", // Set Content-Type header
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/json", // Set Accept header for response format
            },
            data: JSON.stringify(importerData), // Pass the importer data parameter as JSON
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                // This includes all 4xx client errors, so we can handle them gracefully
                return status < 500;
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response so it can be handled in the calling function
        }

        return response.data; // Return the data on successful response
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};

export const addExporter = async (exporterData) => {
    try {
        const token = localStorage.getItem("accessToken"); // Retrieve token from localStorage
        const tokenType = "Bearer"; // Default to 'Bearer'

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const data = JSON.stringify(exporterData);

        const response = await axios({
            method: "POST",
            url: `${api_url}partners/exporters`, // API endpoint for adding exporter
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                "Content-Type": "application/json",
            },
            data: data, // Send the partner_id in the request body
            maxBodyLength: Infinity, // Handle large payloads
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the response data
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error;
    }
};

export const updatePartner = async (partnerId, partnerData) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "PUT",
            url: `${api_url}partners/${partnerId}`, // Endpoint URL for updating a partner
            headers: {
                "Content-Type": "application/json", // Set Content-Type header
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/json", // Set Accept header for response format
            },
            data: JSON.stringify(partnerData), // Pass the partner data parameter as JSON
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                // This includes all 4xx client errors, so we can handle them gracefully
                return status < 500;
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response so it can be handled in the calling function
        }

        if (response.status !== 200) {
            // Expecting 200 OK for a successful PUT
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the data on successful response
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};

export const updateValueDeclarant = async (declarantId, partnerData) => {
    try {
        // Retrieve token and token type from localStorage
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if not specified

        if (!token) {
            throw new Error("No access token found, please log in again.");
        }

        // Configure the request
        const response = await axios({
            method: "PUT",
            url: `${api_url}/partners/value-declarants/${declarantId}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `${tokenType} ${token}`,
                Accept: "application/json",
            },
            data: JSON.stringify(partnerData),
            maxBodyLength: Infinity,
            validateStatus: function (status) {
                // Resolve for all statuses less than 500
                return status < 500;
            },
        });

        // Handle specific response codes
        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the response for validation errors
        }

        if (response.status !== 200) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return response.data; // Return the successful response data
    } catch (error) {
        console.error("Error in updating partner:", error.message);
        throw error; // Rethrow the error for further handling
    }
};

export const deletePartner = async (partnerId) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "DELETE",
            url: `${api_url}partners/${partnerId}`, // Endpoint URL for deleting a partner
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/json", // Set Accept header for response format
            },
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                // This includes all 4xx client errors, so we can handle them gracefully
                return status < 500;
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response so it can be handled in the calling function
        }
        if (response.status === 400) {
            console.warn("Bad Request:", response.data);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // if (response.status !== 204) {  // Expecting 204 No Content for a successful DELETE
        //   throw new Error(`HTTP error! status: ${response.status}`);
        // }

        return response.data; // Return the data on successful response
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};

export const getModesOfTransport = async () => {
    try {
        const response = await axios({
            method: "GET",
            url: `${api_url}mode-of-transport`, // Endpoint URL for retrieving modes of transport
            headers: {
                Accept: "application/json", // Set Accept header for response format
            },
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                // This includes all 4xx client errors, so we can handle them gracefully
                return status < 500;
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response so it can be handled in the calling function
        }

        if (response.status !== 200) {
            // Expecting 200 OK for a successful GET
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the data on successful response
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};

export const uploadFiles = async (files) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        // Prepare form data using the native FormData object
        const formData = new FormData();

        // Append each file to the form data
        files.forEach((file) => {
            formData.append("files", file); // Append each file to the form data with the same field name
        });

        const response = await axios({
            method: "POST",
            url: `${api_url}files/upload`, // Endpoint URL for file upload
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/json", // Set Accept header for response format
                // No need for 'Content-Type' header; Axios will automatically set it to 'multipart/form-data'
            },
            data: formData,
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                return status < 500;
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response so it can be handled in the calling function
        }

        if (response.status !== 200) {
            // Expecting 200 OK for a successful POST
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the data on successful response
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};

export const generateXML = async (customsEntryId, useLcy = true, mergeItems = true) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "POST",
            url: `${api_url}generate-xml?customs_entry_id=${customsEntryId}&use_lcy=${useLcy}&merge_items=${mergeItems}`, // Endpoint URL with additional parameters
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/json", // Set Accept header for response format
            },
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                // This includes all 4xx client errors, so we can handle them gracefully
                return status < 500;
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response so it can be handled in the calling function
        }

        if (response.status !== 200) {
            // Expecting 200 OK for a successful POST
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the data on successful response
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};

export const readInvoiceLLM = async (formData) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "POST",
            url: `${api_url}document-analysis/invoice`, // Endpoint URL for file upload
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/json", // Set Accept header for response format
                // No need for 'Content-Type' header; Axios will automatically set it to 'multipart/form-data'
            },
            data: formData, // Use the provided FormData object
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                return status < 500; // Resolve only if the status code is less than 500
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response so it can be handled in the calling function
        }

        if (response.status !== 200) {
            // Expecting 200 OK for a successful POST
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the data on successful response
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};
export const readWaybillLLM = async (formData) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "POST",
            url: `${api_url}document-analysis/waybill`, // Endpoint URL for waybill upload
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/json", // Set Accept header for response format
                // No need for 'Content-Type' header; Axios will automatically set it to 'multipart/form-data'
            },
            data: formData, // Use the provided FormData object
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                return status < 500; // Resolve only if the status code is less than 500
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response so it can be handled in the calling function
        }

        if (response.status !== 200) {
            // Expecting 200 OK for a successful POST
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the data on successful response
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};
export const getFreightTypes = async () => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}freight-types/`, // Ensure HTTPS is used, and the endpoint is correct
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/json", // Set Accept header for expected response type
            },
            maxBodyLength: Infinity,
            validateStatus: function (status) {
                return status < 500; // Allow handling of 4xx errors
            },
        });

        if (response.status === 301 || response.status === 302) {
            throw new Error("Redirection error, make sure you are using HTTPS and the correct endpoint.");
        }

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error; // Optionally rethrow the error for further handling
    }
};

export const getValueDeclarants = async () => {
    try {
        // Retrieve the token from localStorage
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        // Check if the token is present
        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        // Send the request using axios
        const response = await axios({
            method: "GET",
            url: `${api_url}partners/value-declarants`, // The correct endpoint URL
            headers: {
                Authorization: `${tokenType} ${token}`, // Include the Authorization token
                "Content-Type": "application/json", // Set Content-Type header (optional for GET, but good practice)
                Accept: "application/json", // Expect JSON in response
            },
            maxBodyLength: Infinity,
        });

        // Check for a successful response
        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Return the retrieved data
        const data = response.data;
        return data.map((importer) => ({
            id: importer.id,
            name: importer.partner.name,
            address: importer.partner.address,
            country: importer.partner.country,
            phoneContact: importer.partner.phone_contact,
            taxIdentificationNumber: importer.partner.tax_identification_number,
            partner_id: importer.partner.id,
            status: importer.status,
            importer_id: importer.importer_id,
        }));
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally rethrow the error for further handling in the component
        throw error;
    }
};

export const addDeclarant = async (declarantData) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "POST",
            url: `${api_url}partners/declarants`, // Endpoint URL for adding an importer
            headers: {
                "Content-Type": "application/json", // Set Content-Type header
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/json", // Set Accept header for response format
            },
            data: JSON.stringify(declarantData), // Pass the importer data parameter as JSON
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                // This includes all 4xx client errors, so we can handle them gracefully
                return status < 500;
            },
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the data on successful response
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};
export const addValueDeclarant = async (partner) => {
    try {
        // Retrieve the token from localStorage
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        // Check if the token is present
        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        // Create the data payload
        const data = JSON.stringify(partner);

        // Send the POST request using axios
        const response = await axios({
            method: "POST",
            url: `${api_url}partners/value-declarants`, // The correct endpoint URL
            headers: {
                Authorization: `${tokenType} ${token}`, // Include the Authorization token
                "Content-Type": "application/json", // Set Content-Type header for JSON payload
                Accept: "application/json", // Expect JSON in response
            },
            data: data, // Include the data payload
            maxBodyLength: Infinity,
        });

        // Check for a successful response
        if (response.status !== 200 && response.status !== 201) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Return the retrieved data
        return response.data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally rethrow the error for further handling in the component
        throw error;
    }
};
export const createManualExemptions = async (exemptionsData) => {
    try {
        // Retrieve the token from localStorage
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        // Check if the token is present
        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        // Send the POST request using axios
        const response = await axios({
            method: "POST",
            url: `${api_url}manual-exemptions`, // The correct endpoint URL
            headers: {
                Authorization: `${tokenType} ${token}`, // Include the Authorization token
                "Content-Type": "application/json", // Set Content-Type header for JSON payload
                Accept: "application/json", // Expect JSON in response
            },
            data: JSON.stringify(exemptionsData), // Pass the exemptions data parameter as JSON
            maxBodyLength: Infinity,
        });

        // Check for a successful response
        if (response.status !== 200 && response.status !== 201) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Return the retrieved data
        return response.data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally rethrow the error for further handling in the component
        throw error;
    }
};

export const mergeFiles = async (fileUrls) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer";

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "POST",
            url: `${api_url}files/merge`,
            headers: {
                Authorization: `${tokenType} ${token}`,
                "Content-Type": "application/json",
                Accept: "application/pdf",
            },
            data: JSON.stringify(fileUrls),
            responseType: "blob", // Ensure the response is processed as a blob (binary data)
            maxBodyLength: Infinity,
        });

        if (response.status !== 200 && response.status !== 201) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.data;

        // return new Blob([response.data], { type: 'application/pdf' });  // Return the Blob for further processing
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error;
    }
};

export const saveLatencyPerformanceData = async (statisticsData) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer";

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "POST",
            url: `${api_url}document-analysis-statistics`,
            headers: {
                Authorization: `${tokenType} ${token}`,
                "Content-Type": "application/json",
            },
            data: JSON.stringify(statisticsData),
            maxBodyLength: Infinity,
        });

        if (response.status !== 200 && response.status !== 201) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error;
    }
};

export const postClassificationStatistics = async (classificationData) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer";

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "POST",
            url: `${api_url}classification-statistics`,
            headers: {
                Authorization: `${tokenType} ${token}`,
                "Content-Type": "application/json",
            },
            data: JSON.stringify(classificationData),
            maxBodyLength: Infinity,
        });

        if (response.status !== 200 && response.status !== 201) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error;
    }
};

export const getClassificationStatisticsByCustomsEntry = async (customsEntryId) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer";

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}classification-statistics/by-customs-entry/${customsEntryId}`,
            headers: {
                Authorization: `${tokenType} ${token}`,
                "Content-Type": "application/json",
            },
            maxBodyLength: Infinity,
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error;
    }
};
export const updateClassificationStatisticsByInvoiceLines = async (data) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer";

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "PUT",
            url: `${api_url}classification-statistics/by-invoice-lines`,
            headers: {
                Authorization: `${tokenType} ${token}`,
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
            maxBodyLength: Infinity,
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error;
    }
};

export const getUserDetails = async () => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        // Ensure the api_url uses HTTPS and is correctly defined in your environment
        const response = await axios({
            method: "GET",
            url: `${api_url}users/users/me/`,
            headers: {
                Authorization: `${tokenType} ${token}`,
                "Content-Type": "application/json",
            },
            maxBodyLength: Infinity,
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error;
    }
};

export const postDeclarationStatistics = async (declarationData) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "POST",
            url: `${api_url}declaration-statistics`,
            headers: {
                Authorization: `${tokenType} ${token}`,
                "Content-Type": "application/json",
            },
            maxBodyLength: Infinity,
            data: JSON.stringify(declarationData),
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error;
    }
};

export const postNewCustomer = async (customerData) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "POST",
            url: `${api_url}mawi-customers`,
            headers: {
                Authorization: `${tokenType} ${token}`,
                "Content-Type": "application/json",
            },
            maxBodyLength: Infinity,
            data: JSON.stringify(customerData),
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error;
    }
};

export const createUser = async (userData) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer";

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "POST",
            url: `${api_url}users`, // Ensure HTTPS and use the correct API URL
            headers: {
                Authorization: `${tokenType} ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*", // Adding CORS headers
                "Access-Control-Allow-Headers": "Authorization, Content-Type",
            },
            maxBodyLength: Infinity,
            data: JSON.stringify(userData),
            validateStatus: function (status) {
                return status < 500; // Allow handling of 4xx errors
            },
        });

        if (response.status !== 200 && response.status !== 201) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data;
    } catch (error) {
        if (error.response) {
            console.error("Error response:", error.response.data);
        } else if (error.request) {
            console.error("Error request:", error.request);
        } else {
            console.error("Error message:", error.message);
        }
        throw error;
    }
};

export const generateCaricomPdf = async (entry_id, cargo_details, referenceNumber = "") => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}generate-caricom-pdf?customs_entry_id=${entry_id}&cargo_details=${encodeURIComponent(
                cargo_details
            )}`, // Insert the parameters into the URL
            headers: {
                Authorization: `${tokenType} ${token}`, // Authorization header
                Accept: "application/pdf", // Accept PDF response format
            },
            responseType: "blob", // Ensures the response is treated as binary
            maxBodyLength: Infinity, // To handle large responses
            validateStatus: function (status) {
                return status < 500; // Handle all statuses less than 500
            },
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Handle the PDF download
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `CARICOM - ${referenceNumber}.pdf`); // Use the reference number for the file name
        document.body.appendChild(link);
        link.click();
        link.remove();

        return response.data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error;
    }
};

export const generateBreakdownSheet = async (customs_entry_id, referenceNumber = "") => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}breakdown-sheet?customs_entry_id=${customs_entry_id}`, // Insert the customs_entry_id into the URL
            headers: {
                Authorization: `${tokenType} ${token}`, // Authorization header
                Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Accept Excel format
            },
            responseType: "blob", // Treat the response as binary
            maxBodyLength: Infinity, // To handle large responses
            validateStatus: function (status) {
                return status < 500; // Resolve for all statuses less than 500
            },
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Handle the Excel file download
        const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Breakdown Sheet - ${referenceNumber}.xlsx`); // Use the reference number for the file name
        document.body.appendChild(link);
        link.click();
        link.remove();

        return response.data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error;
    }
};

export const generateSpecialExemptionPDF = async (
    customsEntryId,
    specialExemptionId,
    valueDeclarantId,
    printOnBlankPage = true,
    referenceNumber = ""
) => {
    try {
        const token = localStorage.getItem("accessToken"); // Retrieve token from localStorage
        const tokenType = "Bearer"; // Default to 'Bearer'

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}special-exemption?customs_entry_id=${customsEntryId}&special_exemption_id=${specialExemptionId}&value_declarant_id=${valueDeclarantId}&print_on_blank_page=${printOnBlankPage}`, // Updated URL with print_on_blank_page parameter
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/pdf", // Expect PDF in response
            },
            responseType: "blob", // Treat response as binary data (for PDF)
            maxBodyLength: Infinity,
            validateStatus: function (status) {
                return status < 500; // Resolve only if status code is less than 500
            },
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Handle the PDF download
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `special_exemption_${referenceNumber}.pdf`); // Set the file name
        document.body.appendChild(link);
        link.click();
        link.remove();

        return response.data; // Return the data if needed
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error;
    }
};

export const getSpecialExemptionDeclarations = async (skip = 0, limit = 100) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}special-exemptions?skip=${skip}&limit=${limit}`, // Pass skip and limit as query parameters
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                "Content-Type": "application/json",
            },
            maxBodyLength: Infinity, // To handle large payloads
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Return the data response
        return response.data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error; // Rethrow the error for further handling if needed
    }
};

export const getCustomsOvertime = async (customsEntryId, cargoDetails, startTime) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}customs-overtime?customs_entry_id=${customsEntryId}&cargo_details=${encodeURIComponent(
                cargoDetails
            )}&start_time=${encodeURIComponent(startTime)}`, // Pass parameters dynamically
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                "Content-Type": "application/json",
            },
            maxBodyLength: Infinity, // Handle large payloads
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Return the data response
        return response.data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error; // Rethrow the error for further handling if needed
    }
};

export const generateCustomsOvertimePDF = async (customsEntryId, cargoDetails, startTime, referenceNumber = "") => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}customs-overtime?customs_entry_id=${customsEntryId}&cargo_details=${encodeURIComponent(
                cargoDetails
            )}&start_time=${encodeURIComponent(startTime)}`, // Pass parameters dynamically
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/pdf", // Ensure that the response is expected to be a PDF
            },
            responseType: "blob", // Handle response as binary (PDF)
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                return status < 500; // Allow handling of 4xx errors
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response for further handling
        }

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Handle the PDF download
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Customs Overtime TT - ${referenceNumber}.pdf`); // File name with reference number
        document.body.appendChild(link);
        link.click();
        link.remove();

        return response.data; // Optionally return the data for further handling
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error; // Rethrow the error for further handling if needed
    }
};

export const generateDepositPDF = async (customsEntryId, depositReason = "", referenceNumber = "") => {
    try {
        const token = localStorage.getItem("accessToken"); // Retrieve token from localStorage
        const tokenType = "Bearer"; // Default to 'Bearer'

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}deposit?customs_entry_id=${customsEntryId}&deposit_reason=${encodeURIComponent(
                depositReason
            )}`, // Updated URL with deposit_reason parameter
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/pdf", // Expect PDF in response
            },
            responseType: "blob", // Treat response as binary data (for PDF)
            maxBodyLength: Infinity,
            validateStatus: function (status) {
                return status < 500; // Resolve only if status code is less than 500
            },
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Handle the PDF download
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `deposit_${referenceNumber}.pdf`); // Set the file name
        document.body.appendChild(link);
        link.click();
        link.remove();

        return response.data; // Return the data if needed
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error;
    }
};

export const submitValuationForm = async (valuationData) => {
    try {
        const token = localStorage.getItem("accessToken"); // Retrieve token from localStorage
        const tokenType = "Bearer"; // Default to 'Bearer'

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "POST",
            url: `${api_url}valuation-forms`,
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                "Content-Type": "application/json",
            },
            data: JSON.stringify(valuationData), // Pass the valuation form data dynamically
            maxBodyLength: Infinity,
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the response data
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error;
    }
};

export const searchValuationForms = async (entryId) => {
    try {
        const token = localStorage.getItem("accessToken"); // Retrieve token from localStorage
        const tokenType = "Bearer"; // Default to 'Bearer'

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}valuation-forms/search?entry_id=${entryId}`, // Dynamic entry_id
            headers: {
                Authorization: `${tokenType} ${token}`, // Use token for authorization
            },
            maxBodyLength: Infinity,
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the response data
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error;
    }
};

export const updateValuationForm = async (formData) => {
    try {
        const token = localStorage.getItem("accessToken"); // Retrieve token from localStorage
        const tokenType = "Bearer"; // Default to 'Bearer'

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "PUT",
            url: `${api_url}valuation-forms/${formData.id}`, // Dynamic ID for the valuation form
            headers: {
                Authorization: `${tokenType} ${token}`, // Use token for authorization
                "Content-Type": "application/json",
            },
            data: JSON.stringify(formData),
            maxBodyLength: Infinity,
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the response data
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error;
    }
};

export const generateValuationFormPDF = async (
    customsEntryId,
    valuationFormId,
    printOnBlankPage = false,
    referenceNumber = "",
    invoiceNumbers = ""
) => {
    try {
        const token = localStorage.getItem("accessToken"); // Retrieve token from localStorage
        const tokenType = "Bearer"; // Default to 'Bearer'

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}generate-valuation-form-pdf?customs_entry_id=${customsEntryId}&valuation_form_id=${valuationFormId}&print_on_blank_page=${printOnBlankPage}&invoice_numbers=${invoiceNumbers}`, // Updated URL with new parameter
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/pdf", // Expect PDF in response
            },
            responseType: "blob", // Treat response as binary data (for PDF)
            maxBodyLength: Infinity,
            validateStatus: function (status) {
                return status < 500; // Resolve only if status code is less than 500
            },
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Handle the PDF download
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `valuation_form_${referenceNumber}.pdf`); // Set the file name
        document.body.appendChild(link);
        link.click();
        link.remove();

        return response.data; // Return the data if needed
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error;
    }
};
export const generateValuationFormGYPDF = async (
    customsEntryId,
    valuationFormId,
    printOnBlankPage = false,
    referenceNumber = ""
) => {
    try {
        const token = localStorage.getItem("accessToken"); // Retrieve token from localStorage
        const tokenType = "Bearer"; // Default to 'Bearer'

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}gy-generate-valuation-form-pdf?customs_entry_id=${customsEntryId}&valuation_form_id=${valuationFormId}&print_on_blank_page=${printOnBlankPage}`, // Updated URL with new parameter
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/pdf", // Expect PDF in response
            },
            responseType: "blob", // Treat response as binary data (for PDF)
            maxBodyLength: Infinity,
            validateStatus: function (status) {
                return status < 500; // Resolve only if status code is less than 500
            },
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Handle the PDF download
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `valuation_form_${referenceNumber}.pdf`); // Set the file name
        document.body.appendChild(link);
        link.click();
        link.remove();

        return response.data; // Return the data if needed
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error;
    }
};
export const getCommodityCode = async (itemDescriptions) => {
    const token = localStorage.getItem("accessToken");
    const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set
    try {
        const response = await axios({
            url: `${api_url}classifier`,
            method: "POST",
            headers: {
                Authorization: `${tokenType} ${token}`,
                "Content-Type": "application/json",
            },
            data: itemDescriptions,
        });
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 404) || error.response.status === 500) {
            return null;
        } else {
            console.error("API error:", error);
            throw error;
        }
    }
};

export const getCommodityCodeCharges = async (commodityCode) => {
    try {
        const token = localStorage.getItem("accessToken"); // Retrieve token from localStorage
        const tokenType = "Bearer"; // Default to 'Bearer'

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}commodity-codes/${commodityCode}`, // API endpoint for commodity code
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
            },
            maxBodyLength: Infinity, // Handle large payloads
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the response data
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error;
    }
};

export const getCurrentUser = async () => {
    try {
        const token = localStorage.getItem("accessToken"); // Retrieve token from localStorage
        const tokenType = "Bearer"; // Default to 'Bearer'

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}users/users/me/`,
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
            },
            maxBodyLength: Infinity, // Handle large payloads
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the response data
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error;
    }
};

export const addSupplierPartner = async (partnerData) => {
    try {
        const token = localStorage.getItem("accessToken"); // Retrieve token from localStorage
        const tokenType = "Bearer"; // Default to 'Bearer'

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const data = JSON.stringify(partnerData);

        const response = await axios({
            method: "POST",
            url: `${api_url}partners/suppliers/`,
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                "Content-Type": "application/json",
            },
            data: data,
            maxBodyLength: Infinity, // Handle large payloads
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the response data
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        throw error;
    }
};

export const getMawiCustomers = async () => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "GET",
            url: `${api_url}api/mawi-customers`,
            headers: {
                Authorization: `${tokenType} ${token}`,
                "Content-Type": "application/json",
            },
            maxBodyLength: Infinity,
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = response.data;

        return data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
    }
};

export const getSubscriptions = async (skip = 0, limit = 100) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer";

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: 'GET',
            url: `${api_url}subscriptions?skip=${skip}&limit=${limit}`,
            headers: {
                'Authorization': `${tokenType} ${token}`
            },
            maxBodyLength: Infinity
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = response.data;
        return data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
    }
};

export const createCustomer = async (customerData) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer";

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: 'POST',
            url: `${api_url}mawi-customers`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${tokenType} ${token}`
            },
            maxBodyLength: Infinity,
            data: JSON.stringify(customerData)
        });

        if (response.status !== 201) {  // Assuming a successful creation returns a 201 status
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = response.data;
        return data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
    }
};

export const updateCustomer = async (customerId, customerData) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer";

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: 'PUT',
            url: `${api_url}mawi-customers/${customerId}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${tokenType} ${token}`
            },
            maxBodyLength: Infinity,
            data: JSON.stringify(customerData)
        });

        if (response.status !== 200) {  // Assuming a successful update returns a 200 status
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = response.data;
        return data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
    }
};

export const getCompanyUsers = async (companyId) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer";

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: 'GET',
            url: `${api_url}users/company/${companyId}`,
            headers: {
                'Authorization': `${tokenType} ${token}`
            },
            maxBodyLength: Infinity
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = response.data;
        return data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
    }
};

export const getCustomsEntriesCount = async (params = {}) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer";

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        // Build query string from optional parameters
        const queryParams = new URLSearchParams();
        if (params.start_date) queryParams.append('start_date', params.start_date);
        if (params.end_date) queryParams.append('end_date', params.end_date);
        if (params.user_id) queryParams.append('user_id', params.user_id); // only append if user_id is provided
        if (params.status) queryParams.append('status', params.status);

        const response = await axios({
            method: 'GET',
            url: `${api_url}customs-entries/search/count?${queryParams.toString()}`,
            headers: {
                'Authorization': `${tokenType} ${token}`
            },
            maxBodyLength: Infinity
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = response.data;
        return data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
    }
};

export const searchCustomsEntriesUpdated = async (params = {}, limit = 60, offset = 0) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer"; // Default to 'Bearer' if tokenType is not set

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        // Build query string from optional parameters
        const queryParams = new URLSearchParams();
        if (params.status) queryParams.append('status', params.status);
        if (params.start_date) queryParams.append('start_date', params.start_date);
        if (params.end_date) queryParams.append('end_date', params.end_date);
        if (params.user_id) queryParams.append('user_id', params.user_id); // only append if user_id is provided
        if (params.entry_number) queryParams.append('entry_number', params.entry_number);
        // Add limit and offset for pagination
        queryParams.append('limit', limit);
        queryParams.append('offset', offset);

        const response = await axios({
            method: "GET",
            url: `${api_url}customs-entries/search?${queryParams.toString()}`, // Constructed URL with query params
            headers: {
                Authorization: `${tokenType} ${token}`, // Include Authorization header with token
                Accept: "application/json", // Set Accept header for response format
            },
            maxBodyLength: Infinity, // To handle large payloads if necessary
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                // This includes all 4xx client errors, so we can handle them gracefully
                return status < 500;
            },
        });

        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response; // Return the full response so it can be handled in the calling function
        }

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data; // Return the data on successful response
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
        // Optionally, rethrow the error if further handling is needed
        throw error;
    }
};


export const updateUser = async (userId, userData) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer";

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: 'PUT',
            url: `${api_url}users/${userId}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${tokenType} ${token}`
            },
            maxBodyLength: Infinity,
            data: JSON.stringify(userData)
        });

        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = response.data;
        return data;
    } catch (error) {
        console.error("There was a problem with the request:", error.message);
    }
};

export const getCustomers = async() => {
    try {
      const token = localStorage.getItem("accessToken");
      const tokenType = localStorage.getItem("tokenType") || "Bearer";

      if (!token) {
        throw new Error("No access token found, please login again.");
      }

      const response = await axios({
        method: "GET",
        url: `${api_url}mawi-customers`,
        headers: {
          Authorization: `${tokenType} ${token}`,
          "Content-Type": "application/json",
        },
        maxBodyLength: Infinity,
      });

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error("There was a problem with the request:", error.message);
    }
  }

  export const deleteCommercialInvoice = async (invoiceId) => {
    try {
        const token = localStorage.getItem("accessToken");
        const tokenType = localStorage.getItem("tokenType") || "Bearer";

        if (!token) {
            throw new Error("No access token found, please login again.");
        }

        const response = await axios({
            method: "DELETE",
            url: `${api_url}commercial-invoices/${invoiceId}`,
            headers: {
                Authorization: `${tokenType} ${token}`,
                Accept: "application/json",
            },
            maxBodyLength: Infinity,
            validateStatus: function (status) {
                // Resolve only if the status code is less than 500
                return status < 500;
            },
        });

        // Handle different response statuses
        if (response.status === 422) {
            console.warn("Validation error:", response.data);
            return response;
        }

        if (response.status === 400) {
            console.warn("Bad Request:", response.data);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.data;
    } catch (error) {
        console.error("Failed to delete commercial invoice:", error.message);
        throw error;
    }
};





