
import stringSimilarity from 'string-similarity';
import { parse, format } from 'date-fns';
import { enUS } from 'date-fns/locale';

import * as FastAPI from '@/composables/FastAPI';

export const formatWorkSheetData = (referenceNumber, country, declarantName, declarantTIN, regimeType, importerName, importerTIN, exporterName, exporterTIN, vesselName, portOfDischarger, deposit, containerCharges, additionalCharges, localFee, freightAmount, insurance, otherCharges, freightCurrency, freightRateOfExchange, insuranceCurrency, insuranceRateOfExchange, otherChargesCurrency, otherChargesRateOfExchange, manualExemptionApplied = null, arrival_date = "") => {
    console.log("Reference Number: ", referenceNumber)
    const workSheetObject = {
        relieved: manualExemptionApplied,
        reference_number: referenceNumber,
        country: country,
        declarant: {
            name: declarantName,
            tin: declarantTIN
        },
        regime: regimeType,
        waybill: {
            importer: {
                name: importerName,
                tin: importerTIN
            },
            exporter: {
                name: exporterName,
                tin: exporterTIN
            },
            vessel: vesselName,
            port_of_discharge: portOfDischarger,
            arrival_date: arrival_date,
            charges: {
                international_freight: {
                    amount: parseFloat(verifyNumbers(freightAmount)),
                    currency: freightCurrency,
                    rate_of_exchange: freightRateOfExchange
                },
                insurance: {
                    amount: parseFloat(verifyNumbers(insurance)),
                    currency: insuranceCurrency,
                    rate_of_exchange: insuranceRateOfExchange
                },
                other_charges: {
                    amount: parseFloat(verifyNumbers(otherCharges)),
                    currency: otherChargesCurrency,
                    rate_of_exchange: otherChargesRateOfExchange
                }
            },
            // external_freight: parseFloat(verifyNumbers(freightAmount)),
            // insurance: parseFloat(verifyNumbers(insurance)),
            // other_charges: parseFloat(verifyNumbers(otherCharges))
        },
        additional_local_charges: {
            deposit: deposit,
            container_charges: containerCharges,
            additional_charges: additionalCharges,
            local_fee: localFee
        }
    }

    return workSheetObject;

}

// export const formatWorkSheetData = (referenceNumber, country, declarantName, declarantTIN, regimeType, importerName, importerTIN, exporterName, exporterTIN, vesselName, portOfDischarger, deposit, containerCharges, additionalCharges, localFee, freightAmount, insurance, otherCharges) => {
//     const workSheetObject = {
//         reference_number: referenceNumber,
//         country: country,
//         declarant: {
//             name: declarantName,
//             tin: declarantTIN
//         },
//         regime: regimeType,
//         waybill: {
//             importer: {
//                 name: importerName,
//                 tin: importerTIN
//             },
//             exporter: {
//                 name: exporterName,
//                 tin: exporterTIN
//             },
//             vessel: vesselName,
//             port_of_discharge: portOfDischarger,
//             external_freight: parseFloat(verifyNumbers(freightAmount)),
//             insurance: parseFloat(verifyNumbers(insurance)),
//             other_charges: parseFloat(verifyNumbers(otherCharges))
//         },
//         valuation: {
//             additional_local_charges: {
//                 deposit: deposit,
//                 container_charges: containerCharges,
//                 additional_charges: additionalCharges,
//                 local_fee: localFee
//             }
//         }
//     }

//     return workSheetObject;

// }

const verifyNumbers = (data) => {
    if(data === undefined || data === null || data === '' || data === '0.0' || isNaN(data) || data === "N/A"){
        return 0.0
    }
    return data
}

export const parseFloatFromString = (inputString) => {
    if (!inputString || inputString === undefined || inputString === null) {
        return 0;
    }

    if (typeof inputString === "number") {
        return inputString;
    }
    // Remove non-numeric characters except for period (.)
    let numericString = inputString.replace(/[^0-9.]/g, "");

    // Parse the numeric string as a float
    let floatValue = parseFloat(numericString);

    return floatValue;
}

const cacluateCIF = (invoiceTotal, totalFOB, freight, inland, insurance, otherCharges) => {
    invoiceTotal = parseFloat(verifyNumbers(invoiceTotal));
    totalFOB = parseFloat(verifyNumbers(totalFOB));
    freight = parseFloat(verifyNumbers(freight));
    inland = parseFloat(verifyNumbers(inland));
    insurance = parseFloat(verifyNumbers(insurance));
    otherCharges = parseFloat(verifyNumbers(otherCharges));
    console.log("Calculating CIF...")
    console.log(invoiceTotal, parseFloat(totalFOB), freight, inland, insurance, otherCharges);
    const freightSplit = (invoiceTotal / parseFloat(totalFOB)) * freight;
    return invoiceTotal + freightSplit + inland + insurance + parseFloat(otherCharges);
}

export const formatCharges = (chargeData, vatApplicable) => {
    console.log("VAT Applicable: ", vatApplicable)
    console.log("Charge Data: ", chargeData);
    let charges = [];
    for (const [name, chargeDetails] of Object.entries(chargeData)) {
        let chargeObj = {
            name,
            value: "0", // Default value
            id: null,
            operand_field: "",
            subjected_to_vat: true

        };

        chargeDetails.forEach(detail => {
            if (detail.charge_amount !== undefined) {
                chargeObj.value = detail.charge_amount;
                chargeObj.id = detail.tariff_charge_id;
            }
            // if(detail.name === "value added tax"){
            //     if(vatApplicable){
            //         chargeObj.value = "12.5";
            //     }
            //     else chargeObj.value = "0";
            // }
        });

        if(chargeDetails[1].operand_field !== undefined){
            chargeObj.operand_field = chargeDetails[1].operand_field;
        }
        if(chargeDetails[1].subjected_to_vat !== undefined){
            chargeObj.subjected_to_vat = chargeDetails[1].subjected_to_vat;
        }


        charges.push(chargeObj);
    }

    return charges;
}



export const generateXMLData = (invoices, referenceNumber, waybillNumber, grossWeight, netWeight, packageQuantity, packageType, marksAndNumbers, freightAmount, availableCPCCodes, totalFOB, tradeAgreementOptions) => {
    console.log("Available CPC Codes: ", availableCPCCodes)
    console.log("Total Gross Weight: ", grossWeight)
    let cumulativeWeight = 0;  // This will track the cumulative gross weight calculated
    let sum = 0;
    return invoices.map((invoice, invoiceIndex, invoiceArray) => {
        return {
            exworks: parseFloat(invoice.invoice_total),
            invoice_currency: invoice.currency,
            rate_of_exchange: parseFloat(invoice.rate_of_exchange),
            supplementary_charges_and_deductions: {
                internal_freight: parseFloat(verifyNumbers(invoice.inland)),
                insurance: parseFloat(verifyNumbers(invoice.insurance)),
                other: parseFloat(verifyNumbers(invoice.otherCharges)),
                deductions: 0,
            },
            line_items: invoice.lines.map((item, itemIndex, array) => {
                let gross_weight = (parseFloat(item.extension_price) / totalFOB) * parseFloat(grossWeight);
                sum += gross_weight;
                if (itemIndex === array.length - 1 && invoiceIndex === invoiceArray.length - 1) {
                    // For the last item, adjust the weight to ensure total weights sum up to the total gross weight
                    gross_weight = parseFloat(grossWeight) - cumulativeWeight;
                    gross_weight = Number(gross_weight.toFixed(2));
                    // gross_weight = Math.floor(gross_weight * 100) / 100;
                    console.log("Total Gross Weight: ", grossWeight)
                    console.log("Last Item Gross Weight: ", gross_weight)
                    console.log("Cumulative Weight: ", cumulativeWeight)

                } else {
                    // For all other items, truncate the weight to two decimal places
                    gross_weight = Math.floor(gross_weight * 100) / 100;
                    console.log(`Item Gross Weight: ${gross_weight} - Sum: ${sum}`)
                    cumulativeWeight += gross_weight;
                }
                const net_weight = parseFloat(gross_weight) - (parseFloat(gross_weight) / 10)
                
                return {
                    description: item.description,
                    quantity: parseFloat(item.quantity),
                    unit_cost: parseFloat(item.unit_price),
                    exworks: parseFloat(item.extension_price),
                    cpc_code: parseInt(availableCPCCodes.find(cpc => cpc.cpcId === item.cpcCode)?.code),
                    country_origin_code: item.country_of_origin,
                    anc_code: item.npc_code,
                    trade_agreement: tradeAgreementOptions.find(agreement => agreement.id === item.tradeAgreement)?.code || "",
                    commodity: `${item.tariff_code.replace(/\./g, '')}`,
                    gross_weight: parseFloat(gross_weight),
                    net_weight: Math.floor(net_weight * 100) / 100,
                    package_quantity: invoiceIndex === 0 && itemIndex === 0 ? parseFloat(packageQuantity) : 0,
                    kind_of_package: packageType,
                    marks_and_numbers: marksAndNumbers,
                }
            })
        }
    });
}

export const stringToNumber = (currencyString) => {
    // Remove the dollar sign and any commas
    const numericString = currencyString.replace(/[$,\s]/g, '');

    // Convert the resulting string to a number
    const number = parseFloat(numericString);

    // Return the number
    return number;
}

export const trimStringToLimit = (inputString, limit = 200) =>{
    if (inputString.length > limit) {
        return inputString.substring(0, limit);
    }
    return inputString;
}

// export const formatVehicleDataXML = (vehicles, availableCPCCodes, totalFOB = 0.0, grossWeight = 200) => {
//     // let cumulativeWeight = 0;  // This will track the cumulative gross weight calculated
//     // let sum = 0;
//     return vehicles.flatMap(vehicle => 
//         vehicle.details.map(detail => ({
//             description: trimStringToLimit(vehicle.description, 200),
//             quantity: detail.quantity,
//             unit_cost: vehicle.unitPrice,
//             exworks: parseFloat(detail.quantity) * parseFloat(vehicle.unitPrice),
//             cpc_code: parseInt(availableCPCCodes.find(cpc => cpc.cpcId === detail.cpcCode)?.code),
//             country_origin_code: 'US',
//             anc_code: detail.npcCode, 
//             commodity: vehicle.thnNumber.replace(/\./g, ""),
//             gross_weight: vehicle.grossWeight,
//             net_weight: vehicle.netWeight,
//             package_quantity: 1,
//             kind_of_package: 'CT',
//             marks_and_numbers: `CHASSIS # ${detail.chassisNumber}`,
//             chassis_number: detail.chassisNumber,
//             curb_weight: vehicle.curbWeight,
//             engine_number: detail.engineNumber,
//             exterior_color: detail.exteriorColor,
//             customer: detail.customerName,
//             customer_tin: detail.customerTIN,
//             instruction_code: detail.brokerInstructions,
//             engine_displacement: parseFloat(vehicle.engineDisplacement),
//             manufacture_year: detail.manufactureYear,
//             number_of_seats: vehicle.numberOfSeats,
//             number_of_doors: vehicle.numberOfDoors,
//             tyre_size: vehicle.tyreSize,
//             model_code: vehicle.modelCode,
//             fuel_type: vehicle.fuelType,
//             seat_position: vehicle.seatPosition,
//             used_flag: false,
//             trade_agreement: '',
//             model_name: vehicle.modelCode,
//         }))
//     );
// }

export const formatVehicleDataXML = (vehicles, availableCPCCodes, totalFOB, totalGrossWeight = 200, countryOfOrigin = 'US') => {
    let cumulativeWeight = 0;  // This will track the cumulative gross weight calculated
    
    return vehicles.flatMap((vehicle, vehicleIndex, vehicleArray) => 
        vehicle.details.map((detail, detailIndex, detailArray) => {
            let gross_weight = (parseFloat(detail.quantity) * parseFloat(vehicle.unitPrice) / totalFOB) * parseFloat(totalGrossWeight);

            if (detailIndex === detailArray.length - 1 && vehicleIndex === vehicleArray.length - 1) {
                // For the last item, adjust the weight to ensure total weights sum up to the total gross weight
                gross_weight = parseFloat(totalGrossWeight) - cumulativeWeight;
                gross_weight = Number(gross_weight.toFixed(2));
            } else {
                // For all other items, truncate the weight to two decimal places
                gross_weight = Math.floor(gross_weight * 100) / 100;
                cumulativeWeight += gross_weight;
            }

            const net_weight = parseFloat(gross_weight) - (parseFloat(gross_weight) / 10);

            return {
                description: trimStringToLimit(vehicle.description, 200),
                quantity: 1,
                unit_cost: vehicle.unitPrice,
                exworks: parseFloat(vehicle.unitPrice),
                cpc_code: parseInt(availableCPCCodes.find(cpc => cpc.cpcId === detail.cpcCode)?.code),
                country_origin_code: countryOfOrigin,
                anc_code: detail.npcCode, 
                commodity: vehicle.thnNumber.replace(/\./g, ""),
                gross_weight: parseFloat(gross_weight),
                net_weight: Math.floor(net_weight * 100) / 100,
                package_quantity: 1,
                kind_of_package: 'CT',
                marks_and_numbers: `CHASSIS # ${detail.chassisNumber}`,
                chassis_number: detail.chassisNumber,
                curb_weight: vehicle.curbWeight || 0,
                engine_number: detail.engineNumber,
                exterior_color: detail.exteriorColor,
                customer: detail.customerName || "",
                customer_tin: detail.customerTIN || "",
                instruction_code: detail.brokerInstructions,
                engine_displacement: parseFloat(vehicle.engineDisplacement),
                manufacture_year: detail.manufactureYear,
                number_of_seats: vehicle.numberOfSeats,
                number_of_doors: vehicle.numberOfDoors,
                tyre_size: vehicle.tyreSize || "",
                model_code: vehicle.modelCode,
                fuel_type: vehicle.fuelType,
                seat_position: vehicle.seatPosition,
                used_flag: false,
                trade_agreement: '',
                model_name: vehicle.modelCode,
            }
        })
    );
}

export const discountCheck = (invoices) => {
    if (!Array.isArray(invoices)) {
        return false;
    }

    // Iterate over each invoice
    for (let invoice of invoices) {
        // Check if the invoice has items
        if (!invoice || !invoice.invoice_items || !Array.isArray(invoice.invoice_items)) {
            continue;
        }

        // Iterate over the items to check for discounted_unit_price
        for (let item of invoice.invoice_items) {
            const unitPrice = parseFloat(item.unit_price);
            const discountedPrice = parseFloat(item.discounted_unit_price);

            // Check if discountedPrice is a valid number and is different from unitPrice
            if (item.discounted_unit_price && !isNaN(discountedPrice) && discountedPrice !== unitPrice) {
                return true; // Return true if any item has a valid discounted_unit_price different from unit_price
            }
        }
    }

    return false; // Return false if no items have a valid discounted_unit_price in any invoice
}

export const formatVehicleDataWorksheet = (vehicles, availableCPCCodes, countryOfOrigin = 'US') => {
    return vehicles.flatMap(vehicle => 
        vehicle.details.map(detail => ({
            description: `${vehicle.description} CN: ${detail.chassisNumber}`,
            quantity: 1,
            unit_cost: vehicle.unitPrice,
            exworks: parseFloat(vehicle.unitPrice),
            cpc_code: `${parseInt(availableCPCCodes.find(cpc => cpc.cpcId === detail.cpcCode)?.code)}`,
            country_of_origin: countryOfOrigin,
            anc_code: detail.npcCode, 
            commodity: vehicle.commodity,
            gross_weight: vehicle.grossWeight,
            net_weight: vehicle.netWeight,
            package_quantity: 1,
            kind_of_package: 'CT',
            marks_and_numbers: `CHASSIS # ${detail.chassisNumber}`,
            chassis_number: detail.chassisNumber,
            curb_weight: vehicle.curbWeight,
            engine_number: detail.engineNumber,
            exterior_color: detail.exteriorColor,
            customer: detail.customerName,
            customer_tin: detail.customerTIN,
            instruction_code: detail.brokerInstructions,
            engine_displacement: parseFloat(vehicle.engineDisplacement),
            manufacture_year: detail.manufactureYear,
            number_of_seats: vehicle.numberOfSeats,
            number_of_doors: vehicle.numberOfDoors,
            tyre_size: vehicle.tyreSize,
            model_code: vehicle.modelCode,
            fuel_type: vehicle.fuelType,
            seat_position: vehicle.seatPosition,
            used_flag: false
        }))
    );
}

export const formatCommercialData = (invoices, freight, totalFOB) => {
    console.log("Invoices: ", invoices)
    return invoices.map(invoice => {
        return {
            name: invoice.invoice_number,
            supplier: invoice.supplier_name,
            exworks: parseFloat(invoice.invoice_total),
            invoice_currency: invoice.currency,
            cif_foreign_currency: cacluateCIF(invoice.invoice_total, totalFOB, freight, invoice.inland, invoice.insurance, invoice.otherCharges),
            rate_of_exchange: invoice.rate_of_exchange,
            cif_local_currency: cacluateCIF(invoice.invoice_total, totalFOB, freight, invoice.inland, invoice.insurance, invoice.otherCharges) * invoice.rate_of_exchange,
            supplementary_charges_and_deductions: {
                internal_freight: parseFloat(verifyNumbers(invoice.inland)),
                insurance: parseFloat(verifyNumbers(invoice.insurance)),
                // external_freight: freight,
                other: parseFloat(verifyNumbers(invoice.otherCharges)),
                deductions: 0,
            },
            line_items: invoice.lines.map(item => {
                return {
                    ...(item.manualExemption ? { relieved: true } : {}),
                    description: item.description,
                    quantity: parseFloat(item.quantity),
                    unit_cost: parseFloat(item.unit_price),
                    exworks: parseFloat(item.extension_price),
                    cpc_code: item.cpcCodeName,
                    trade_agreement: item.tradeAgreementCode || "",
                    country_of_origin: item.country_of_origin,
                    anc_code: item.npcCode, 
                    commodity: item.commodity
                }
            })
        }

    })
}

export const formatInvoiceData = (invoices, freight, totalFOB) => {
    return invoices.map(invoice => {
        return {
            name: invoice.invoice_id,
            supplier: invoice.vendor_name,
            exworks: invoice.invoice_total,
            invoice_currency: invoice.currency,
            cif_foreign_currency: cacluateCIF(invoice.invoice_total, totalFOB, freight, invoice.inland, invoice.insurance, invoice.otherCharges),
            rate_of_exchange: invoice.rate_of_exchange,
            cif_local_currency: cacluateCIF(invoice.invoice_total, totalFOB, freight, invoice.inland, invoice.insurance, invoice.otherCharges) * invoice.rate_of_exchange,
            supplementary_charges_and_deductions: {
                internal_freight: parseFloat(invoice.inland),
                insurance: parseFloat(invoice.insurance),
                // external_freight: parseFloat(freight),
                other: parseFloat(invoice.otherCharges),
                deductions: 0,
            },
            line_items: invoice.lines.map(item => {
                return {
                    description: item.description,
                    quantity: parseFloat(item.quantity),
                    unit_cost: parseFloat(item.unit_price),
                    exworks: parseFloat(item.extension_price),
                    cpc_code: item.cpcCodeName,
                    country_of_origin: item.country_of_origin,
                    anc_code: item.npcCode, 
                    commodity: item.commodity
                }
            })
        }

    })
}

export function getCurrentDateString() {
    const today = new Date();
    
    // Get the day, month, and year
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = today.getFullYear();
    
    // Format the date string as DD/MM/YYYY
    const dateString = `${day}/${month}/${year}`;
    
    return dateString;
  }

export function getDateStringInputField(date = new Date()) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export const formatDepositData = (regimeType, waybillNumber, waybillDate, incoTerms, importer, importNumber, declarant, declarantNumber, referenceNumber, shipper, countryOfOrigin, countryOfOriginCode, meansOfTransport, modeOTransportCode, portOfDischarger, portCode, depositAmount, reason) => {
    return {
        regimeType: regimeType,
        waybillNumber: waybillNumber,
        waybillDate: waybillDate,
        incoTerms: incoTerms,
        termsOfPayment: "SDT",
        importer: importer,
        imorterNo: importNumber === null ? "" : importNumber,
        declarant: declarant,
        declarantNo: declarantNumber,
        reference: referenceNumber,
        consignor: shipper,
        countryOfConsignment: countryOfOrigin,
        countryOfConsignmentCode: countryOfOriginCode,
        meansOfTransportation: meansOfTransport,
        rotation: "/24",
        mode: modeOTransportCode,
        portOfImportation: portOfDischarger,
        portCode: portCode,
        dateOfImportation: getCurrentDateString(),
        depositAmount: parseFloat(depositAmount).toFixed(2),
        reason: reason
    }
}

export const formatCaricomData = (importerNameAddress, exporterNameAddress, referenceNumber, countryOfOrigin, incoTerms, portOfLoading, countryOfDestination, modeOfTransport, vessel, currency, descriptionOfGoods, grossWeight, invoices, packageQuantity, totalCargoValue, freight, insurance, otherCharges, packageType="pks") => {
    let packageList = invoices.flatMap(invoice => {
        return invoice.lines.map(line => {
            let lineGrossWeight = (parseFloat(line.extension_price) / totalCargoValue) * parseFloat(grossWeight);
            let lineNetWeight = lineGrossWeight - (lineGrossWeight / 10)
            return {
                "numberAndKindOfPackages": "",
                "specificationOfCommodities": line.description,
                "netWeight": lineNetWeight.toFixed(2),
                "quantity": parseFloat(line.quantity).toLocaleString('en-US', {minimumFractionDigits: 2}),
                "unitPrice": parseFloat(line.unit_price).toLocaleString('en-US', {minimumFractionDigits: 2}),
                "amount": parseFloat(line.extension_price).toLocaleString('en-US', {minimumFractionDigits: 2}),
                "invoiceNumber": invoice.invoice_number,
            }
        })
    });
    const totalValue = parseFloat(totalCargoValue) + parseFloat(freight) + parseFloat(insurance) + parseFloat(otherCharges);
    console.log("Other Charges:", otherCharges)
    console.log(totalCargoValue, freight, insurance, otherCharges, totalValue)
    packageList[0].numberAndKindOfPackages = `${packageQuantity} ${packageType}(s)`;
    return {
        "sellerNameAddress": exporterNameAddress,
        "invoiceNumber": `${getCurrentDateString()} ${referenceNumber}`,
        "customerOrderNumber": "",
        "otherReferences": "",
        "buyer": "",
        "consigneeNameAddress": importerNameAddress,
        "presentingBank": "",
        "countryOfOrigin": countryOfOrigin,
        "incoterms": incoTerms,
        "portOfLoading": portOfLoading,
        "countryOfFinalDestination": countryOfDestination,
        "modeOfTransport": modeOfTransport,
        "currencyOfSale": currency,
        "otherTransportInformation": vessel,
        "marksAndNumbers": "As Addressed",
        "descriptionOfGoods": descriptionOfGoods,
        "grossWeight": grossWeight.toLocaleString('en-US', {minimumFractionDigits: 2}),
        "packages": packageList,
        "fob": totalCargoValue.toLocaleString('en-US', {minimumFractionDigits: 2}),
        "packaging": "0.00",
        "freight": freight.toLocaleString('en-US', {minimumFractionDigits: 2}),
        "insurance": insurance.toLocaleString('en-US', {minimumFractionDigits: 2}),
        "otherCharges": otherCharges.toLocaleString('en-US', {minimumFractionDigits: 2}),
        "invoiceTotal": totalValue.toLocaleString('en-US', {minimumFractionDigits: 2}),
    }
}

export const initializeCustomsEntryForm = () => {
    return {
        exporters: [],
        importers: [],
        vessels: [],
        ports: [],
        declarants: [],
        incotermsList: [],
        ratesOfExchange: [],
        importerId: '',
        exporterId: '',
        importerName: '',
        exporterName: '',
        importerTaxIdentificationNumber: '',
        exporterTaxIdentificationNumber: '',
        exporterCountry: '',
        declarantId: '',
        declarantTaxIdentificationNumber: '',
        modeOfTransport: '',
        freightType: '',
        waybillNumber: '',
        waybillDate: '',
        vesselId: '',
        vesselName: '',
        incoTerms: '',
        portOfDischargeId: '',
        portOfDischargeName: '',
        netWeight: 0.0,
        grossWeight: 0.0,
        numberOfPackages: 0,
        packageType: '',
        packageTypes: [],
        marksAndNumbers: 'As Addressed',
        freightCurrency: '',
        insuranceCurrency: '',
        otherChargesCurrency: '',
        totalCargoValue: 0.0,
        freightAmount: 0.0,
        insuranceAmount: 0.0,
        otherCharges: 0.0,
        insuranceRateOfExchange: 0.0,
        otherChargesRateOfExchange: 0.0,
        freightRateOfExchange: 0.0,
        rateOfExchange: 0.0,
        regimeType: '',
        regimeTypeCode: '',
        regimeTypeOptions: [],
        depositAmount: 0.0,
        containerCharges: 0.0,
        additionalCharges: 0.0,
        localFee: 0.0,
        countryOfOrigin: '',
        countryOfFinalDestination: 'TT',
        countryLastProvinance: '',
        tradingCountry: ''
    }
}

export const getClassificationStatusCreatePage = (invoiceList) => {
    return invoiceList.some(invoice => {
        return invoice.lines.some(line => !line.tariff_code || line.tariff_code.trim() === '');
      });

}

export const getClassificationStatus = (invoiceList) => {
    return invoiceList.some(invoice => {
        return invoice.lines.some(line => !line.tariff_code || line.tariff_code.trim() === '')
    })
}

export const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
}

export const calculateInvoiceTotal = (extenstionTotal, insurance, inland, otherCharges) => {
    return extenstionTotal + insurance + inland + otherCharges;
}

const normalizeString = (str) => {
    return str
      .toLowerCase()
      .replace(/\bltd\b/g, 'limited')
      .replace(/[^a-z0-9]/g, '');
};

export const findClosestMatch = (searchString, searchArray, searchField = 'name') => {
    const normalizedSearchString = normalizeString(searchString);
    const names = searchArray.map(item => normalizeString(item[searchField]));

    const { bestMatch } = stringSimilarity.findBestMatch(normalizedSearchString, names);
    return searchArray.find(item => normalizeString(item[searchField]) === bestMatch.target);
}

export const parseDate = (dateString) => {
    // Remove ordinal suffixes (like "TH") from the date string
    const cleanDateString = dateString.replace(/(\d+)(ST|ND|RD|TH)/gi, '$1');
  
    const possibleFormats = [
      'dd-MMM-yyyy',
      'dd/MM/yyyy',
      'MMM/dd/yyyy',
      'yyyy-MM-dd',
      'yyyy/MM/dd',
      'dd MMM yyyy',
      'MMM dd, yyyy',
      'dd-MM-yyyy',
      'yyyyMMdd',
      'dd,MMM,yyyy'  // Add this format to handle "19TH,MAR,2024"
    ];
  
    for (const formatString of possibleFormats) {
      const parsedDate = parse(cleanDateString, formatString, new Date(), { locale: enUS });
      if (!isNaN(parsedDate)) {
        return parsedDate;
      }
    }
  
    // If no format matches, try using the built-in Date constructor
    const parsedDate = new Date(cleanDateString);
    if (!isNaN(parsedDate)) {
      return parsedDate;
    }
  
    throw new Error(`Unable to parse date: ${dateString}`);
  };
  
  export const formatDateString = (date) => {
    return format(date, 'yyyy-MM-dd'); // or any desired format
  };

  export const parseFreightCharges = (freightString) => {
    // Remove any non-numeric characters except for the decimal point
    const cleanedString = freightString.replace(/[^0-9.]/g, '');
  
    // Convert the cleaned string to a float number
    const parsedValue = parseFloat(cleanedString);
  
    // Check if the parsed value is a valid number
    if (isNaN(parsedValue)) {
      return 0.0;
    }
  
    return parsedValue;
  };

  export const formatScoonerBLDetails = (exporter, importer, marksAndNumbers, vesselName, countryOfDestination, invoiceLines, freightPayableAt, grossWeight, portOfDischarge, referenceNumber) => {
    return {
        exporter: exporter,
        importer: importer,
        marksAndNumbers: marksAndNumbers,
        vesselName: vesselName,
        countryOfDestination: countryOfDestination,
        invoiceLines: invoiceLines,
        freightPayableAt: freightPayableAt,
        grossWeight: grossWeight,
        portOfDischarge: portOfDischarge,
        date: getCurrentDateString(),
        referenceNumber: referenceNumber
    }
  }

export const transformArray = (data) => {
    // Check if the data is an array of arrays
    if (Array.isArray(data) && Array.isArray(data[0])) {
        // Flatten the array of arrays
        return data.flat();
    }
    // Return the data as is if it's already in the desired format
    return data;
}

export const renameObjectKeys = (invoices) => {
    for (let i = 0; i < invoices.length; i++) {
        let { invoice_items: lines, ...rest } = invoices[i];
        invoices[i] = { lines, ...rest };
    }
    return invoices;
}

export const readFilesLLM = async (url, files) => {
    console.log("Uploading Files", url);
    const formData = new FormData();
    for (const file of files) {
        formData.append("files", file);
        formData.append("commercialInvoice", file);
    }
    const invoiceProcessingStart = new Date();
    return fetch(url, {
        method: "POST",
        body: formData,
    }).then((response) => response.json()).then((data) => {
        console.log("Data: ", data);
        const invoiceProcessingEnd = new Date();
        console.log("Invoice Processing Time: ", invoiceProcessingEnd - invoiceProcessingStart);
        const invoices = renameObjectKeys(data);
        invoices.forEach(invoice => {
            invoice.invoice_total = invoice.invoice_total ? parseFloatFromString(invoice.invoice_total) : (invoice.invoice_exworks_amount ? parseFloatFromString(invoice.invoice_exworks_amount) : 0.00)
            invoice.inland = invoice.invoice_internal_freight_amount ? parseFloatFromString(invoice.invoice_internal_freight_amount) : 0.00;
            invoice.insurance = invoice.invoice_insurance_amount ? parseFloatFromString(invoice.invoice_insurance_amount): 0.00;
            invoice.otherCharges = invoice.invoice_other_charges_amount ? parseFloatFromString(invoice.invoice_other_charges_amount) : 0.00;
            invoice.final_total = parseFloatFromString(invoice.final_total);
            invoice.total_tax = 0.00;
            invoice.subtotal = invoice.invoice_total ? parseFloatFromString(invoice.invoice_total) : (invoice.invoice_exworks_amount ? parseFloatFromString(invoice.invoice_exworks_amount) : 0.00);
            invoice.invoice_number = invoice.invoice_id;
            delete invoice.invoice_id;
            console.log("Invoice Date: ", invoice.invoice_date)
            invoice.invoice_date = invoice.invoice_date ? getDateStringInputField(parseDate(invoice.invoice_date)) : null;
            console.log("Invoice Date: ", invoice.invoice_date)
            invoice.currency = "USD";
            invoice.lines.forEach((item) => {
                item.country_of_origin = "";
                item.quantity = parseFloatFromString(item.quantity);
                item.unit_price = parseFloatFromString(item.unit_price);
                item.extension_price = parseFloatFromString(item.amount).toFixed(2);
                item.vatApplicable = true;
                item.cpcCode = 30;
                item.npcCode = "000";
                // item.vat_applicable = item.vat_applicable === 'Yes'
            });
        });
        const llmResponse = {
            invoiceData: invoices,
            invoiceProcessingTime: invoiceProcessingEnd - invoiceProcessingStart
        }
        return llmResponse;
    });
}

export const formatDateReadFromDB = (databaseDate) => {
    if (databaseDate === "0000-00-00" || !databaseDate) {
        return "";
    } else {
        let dateObject = new Date(databaseDate);
        if (isNaN(dateObject)) {
            return "";
        } else {
            return dateObject.toISOString().split("T")[0];
        }
    }
}

export const getRatesOfExchange = async (currencyList, shippedOnBoardDate, targetCurrency = 190) => {
    let promises = [];
    const uniqueCurrencies = [...new Set(currencyList)];
    uniqueCurrencies.forEach(currency => {
        promises.push(FastAPI.getRateOfExchange(currency, targetCurrency, shippedOnBoardDate));
    });

    return Promise.all(promises).then((rates) => {
        const flattenedRates = rates.flat();
        return flattenedRates;
    });
}

export const mergeArrays = (arr1, arr2) => {
    arr1.forEach(item1 => {
        // Check if the item from array1 already exists in array2 based on the 'id'
        const exists = arr2.some(item2 => item2.id === item1.id);
        
        if (!exists) {
            arr2.push(item1);
        }
    });
};

export const generateErrorMessage = (response) => {
    if (!response || !response.detail) {
        return "An unknown error occurred.";
      }
    
      // Extract error details
      const errorDetails = response.detail;
      
      // Initialize an array to store user-friendly messages
      const userMessages = [];
    
      // Iterate over each error detail object
      errorDetails.forEach(error => {
        if (error.type === "missing") {
          // Extract location info
          const loc = error.loc;
          
          // If loc exists and has more than one element
          if (loc && loc.length > 2) {
            const missingField = loc[2]; // The field name is at the third position
    
            // Generate a user-friendly message
            userMessages.push(`The field "${missingField}" is required but missing.`);
          }
        }
      });
    
      // Join all messages into a single string separated by newlines
      return userMessages.length > 0 ? userMessages.join("\n") : "An unknown error occurred.";
}

export const findCPCNPCReltation = (cpcId, npcId, availableCPCCodes) => {
    // Iterate through each main object in the data array
    for (let item of availableCPCCodes) {
        // Check if the current item's cpcId matches the given cpcId
        if (item.cpcId === cpcId) {
        // If it matches, iterate through its npcCodes array
        for (let npc of item.npcCodes) {
            // Check if the current npc's npcId matches the given npcId
            if (npc.npcId === npcId) {
            // If it matches, return the cpcNpcRelationId
            return npc.cpcNpcRelationId;
            }
        }
        }
    }
    // Return null or an appropriate value if no match is found
    return null;
}
export const renameInvoiceObjectKeys = (invoiceDataArray) => {
    // Iterate over each invoice object in the array
    for (let i = 0; i < invoiceDataArray.length; i++) {
        // Destructure to rename `invoice_items` to `lines`
        let { invoice_items: lines, ...rest } = invoiceDataArray[i];
        // Reassign the object with the renamed key
        invoiceDataArray[i] = { lines, ...rest };
    }
    // Return the updated array
    return invoiceDataArray;
}

export const formatInvoiceDataAfterLLMRead = (invoiceDataArray, defaultCPCId, defaultNPCCode, currencies, countryOfOrigin) => {

    return invoiceDataArray.map((invoice) => {
        // Format the top-level invoice fields
        invoice.invoice_total = invoice.invoice_total
            ? parseFloatFromString(invoice.invoice_total)
            : (invoice.invoice_exworks_amount
                ? parseFloatFromString(invoice.invoice_exworks_amount)
                : 0.00);
        invoice.inland = invoice.invoice_internal_freight_amount
            ? parseFloatFromString(invoice.invoice_internal_freight_amount)
            : 0.00;
        invoice.insurance = invoice.invoice_insurance_amount
            ? parseFloatFromString(invoice.invoice_insurance_amount)
            : 0.00;
        invoice.otherCharges = invoice.invoice_other_charges_amount
            ? parseFloatFromString(invoice.invoice_other_charges_amount)
            : 0.00;
        invoice.final_total = parseFloatFromString(invoice.final_total);
        invoice.invoice_number = invoice.invoice_id;
        invoice.invoice_date = invoice.invoice_date
            ? getDateStringInputField(parseDate(invoice.invoice_date))
            : null;
        invoice.currency = currencies.find((currency) => currency.code === "USD")?.id;

        // Process each invoice line
        if (invoice.lines !== undefined && invoice.lines !== null) {
            invoice.lines.forEach((item) => {
                item.country_of_origin = countryOfOrigin || item.country_of_origin || "";
                item.quantity = parseFloatFromString(item.quantity);
                item.unit_price = parseFloatFromString(item.unit_price);
                
                // Rename 'amount' to 'extension_price' and format it
                item.extension_price = parseFloatFromString(item.amount).toFixed(2);
                
                // Other assignments
                item.vatApplicable = true;
                item.cpcCode = defaultCPCId;
                item.npcCode = defaultNPCCode;
                item.originalLine = structuredClone(item);
            });
        }
        
        return invoice;
    });
}

export const calculateInvoiceFinalTotal = (invoice) => {
    const invoiceTotal = parseFloat(invoice.invoice_total) || 0;
    const inland = parseFloat(invoice.inland) || 0;
    const insurance = parseFloat(invoice.insurance) || 0;
    const otherCharges = parseFloat(invoice.otherCharges) || 0;

    const total = invoiceTotal + inland + insurance + otherCharges;

    return total;
};

export const prepareInvoiceDataForFastAPI = (commercialInvoices, availableCPCCodes, entryId, packageType, packageQuantity, invoiceFileUrls) => {
    const formattedData = commercialInvoices.map((invoice, index) => {
        return {
            invoice_number: invoice.invoice_number,
            invoice_date: invoice.invoice_date,
            invoice_total: invoice.invoice_total,
            inland: invoice.inland,
            insurance: invoice.insurance,
            other_charges: invoice.otherCharges,
            rate_of_exchange_id: invoice.rateOfExchangeId,
            supplier_id: invoice.supplier_id,
            currency_id: invoice.currency,
            customs_entry_id: entryId,
            purchase_order_number: "", // TODO
            file_url: invoiceFileUrls[index],
            invoice_lines: invoice.lines.map((line) => {
                const cpcNpcRelationId = findCPCNPCReltation(line.cpcCode, line.npcCode, availableCPCCodes);
                return {
                    product_code: line.product_code || "",
                    quantity: line.quantity,
                    unit_price: line.unit_price,
                    description: line.description,
                    classification_description: line.classification_description,
                    extension_price: line.extension_price,
                    discount_price: 0, // TODO
                    trade_agreement_id: line.tradeAgreement,
                    commodity_code_id: line.commodityCodeId,
                    country_of_origin_id: line.country_of_origin_id,
                    cpc_npc_code_id: cpcNpcRelationId,
                    package_type_id: packageType,
                    package_quantity: packageQuantity,
                }
            })
        }
    });
    return formattedData;
}

export const findChargeObject = (data, chargeName) => {
    // Loop through the commodity_code_charges array
    for (let chargeEntry of data.commodity_code_charges) {
        // Check if the charge object's name is "import custom duty"
        if (chargeEntry.charge.name.toLowerCase().includes(chargeName.toLowerCase())) {
            return chargeEntry;
        }
    }
    return null;
}

export const setCPCANCAllLines = (commercialInvoices, cpcCode, npcCode) => {
    console.log("Setting CPC & ANC for all lines", commercialInvoices, cpcCode, npcCode);

    return commercialInvoices.map(invoice => {
        invoice.lines = invoice.lines.map(lineItem => {
            return {
                ...lineItem,
                cpcCode: cpcCode,
                npcCode: npcCode
            };
        });
        return invoice;
    });
}

export const filterNullValues = (obj) => {
    return Object.entries(obj)
        .filter(([, value]) => value !== null && value !== undefined)
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
};

export const getTotalPageCount = (invoices) => {
    return invoices.reduce((totalPages, invoice) => {
        return totalPages + (invoice.page_count || 0);
    }, 0);
}

export const insertFullStops = (numberString) => {
    // if (numberString.length !== 11) {
    //     console.log("The string must contain exactly 11 numbers.", numberString);
    //     return "";
    // }

    return numberString.slice(0, 4) + "." + numberString.slice(4, 6) + "." + numberString.slice(6, 8) + "." + numberString.slice(8);
}



