// src/utils/ErrorHandler.js

class ErrorHandler {
    /**
     * Formats error messages from various types of errors into user-friendly messages
     * @param {Error} error - The error object to format
     * @returns {string} A user-friendly error message
     */
    static formatErrorMessage(error) {
        // Default message if we can't parse anything more specific
        let errorMessage = "An error occurred while processing your request";

        if (!error.response) {
            // Handle network or other non-HTTP errors
            return error.message || "Network error. Please check your connection and try again.";
        }

        const { status, data } = error.response;

        // If there's no data, return a status-based message
        if (!data) {
            return this.getStatusBasedMessage(status);
        }

        // Try to extract the error detail
        const errorDetail = data.detail;
        if (!errorDetail) {
            return this.getStatusBasedMessage(status);
        }

        // Process the error detail based on its type and content
        if (typeof errorDetail === 'string') {
            // Handle different error patterns
            if (errorDetail.includes('Integrity error')) {
                errorMessage = this.parseIntegrityError(errorDetail);
            } else if (errorDetail.includes('Validation error')) {
                errorMessage = this.parseValidationError(errorDetail);
            } else if (errorDetail.includes('Foreign key violation')) {
                errorMessage = this.parseForeignKeyError(errorDetail);
            } else if (errorDetail.includes('not found')) {
                errorMessage = this.parseNotFoundError(errorDetail);
            } else {
                // For unknown patterns, clean up the message
                errorMessage = this.cleanErrorMessage(errorDetail);
            }
        } else if (Array.isArray(errorDetail)) {
            // Handle array of errors (common in validation errors)
            errorMessage = errorDetail
                .map(err => this.cleanErrorMessage(err))
                .join('\n');
        } else if (typeof errorDetail === 'object') {
            // Handle structured error objects
            errorMessage = Object.entries(errorDetail)
                .map(([key, value]) => `${this.formatFieldName(key)}: ${value}`)
                .join('\n');
        }

        return errorMessage;
    }

    /**
     * Parses integrity error messages into user-friendly format
     * @param {string} errorDetail - The error detail string
     * @returns {string} Formatted error message
     */
    static parseIntegrityError(errorDetail) {
        // Extract the DETAIL section
        const detailMatch = errorDetail.match(/DETAIL:\s*(.*?)(?=\s*\[|$)/);
        if (!detailMatch) {
            return "A record with this information already exists.";
        }

        const detail = detailMatch[1].trim();

        // Handle different types of integrity errors
        if (detail.includes('Key (')) {
            const keyMatch = detail.match(/Key \((.*?)\)=\((.*?)\)/);
            if (keyMatch) {
                const [, field, value] = keyMatch;
                return this.formatDuplicateKeyMessage(field, value);
            }
        }

        return this.cleanErrorMessage(detail);
    }

    /**
     * Formats duplicate key messages based on the field
     * @param {string} field - The field name
     * @param {string} value - The duplicate value
     * @returns {string} Formatted error message
     */
    static formatDuplicateKeyMessage(field, value) {
        const fieldMessages = {
            'entry_number': `Entry number "${value}" is already in use. Please use a different entry number.`,
            'manifest_reference': `Manifest reference "${value}" is already in use.`,
            'tracking_number': `Tracking number "${value}" is already in use.`,
            'container_number': `Container number "${value}" is already in use.`,
            'invoice_number': `Invoice number "${value}" is already in use.`,
            // Add more field-specific messages as needed
        };

        return fieldMessages[field] || 
               `The ${this.formatFieldName(field)} "${value}" is already in use.`;
    }

    /**
     * Parses validation error messages
     * @param {string} errorDetail - The error detail string
     * @returns {string} Formatted error message
     */
    static parseValidationError(errorDetail) {
        return errorDetail
            .replace(/^validation error:/i, '')
            .split('\n')
            .map(err => this.cleanErrorMessage(err))
            .filter(Boolean)
            .join('\n');
    }

    /**
     * Parses foreign key violation errors
     * @param {string} errorDetail - The error detail string
     * @returns {string} Formatted error message
     */
    static parseForeignKeyError(errorDetail) {
        const match = errorDetail.match(/Key \((.*?)\)=\((.*?)\)/);
        if (match) {
            const [, field, value] = match;
            return `Invalid reference: ${this.formatFieldName(field)} "${value}" does not exist.`;
        }
        return this.cleanErrorMessage(errorDetail);
    }

    /**
     * Parses not found errors
     * @param {string} errorDetail - The error detail string
     * @returns {string} Formatted error message
     */
    static parseNotFoundError(errorDetail) {
        return errorDetail.replace(/^.*?not found:?/i, 'Not found:').trim();
    }

    /**
     * Returns a status-based error message
     * @param {number} status - HTTP status code
     * @returns {string} Status-based error message
     */
    static getStatusBasedMessage(status) {
        const statusMessages = {
            400: "Invalid request. Please check your input and try again.",
            401: "Your session has expired. Please login again.",
            403: "You don't have permission to perform this action.",
            404: "The requested resource was not found.",
            409: "This operation could not be completed due to a conflict.",
            422: "The submitted data is invalid.",
            429: "Too many requests. Please try again later.",
            500: "An internal server error occurred. Please try again later.",
            502: "The server is temporarily unavailable. Please try again later.",
            503: "Service unavailable. Please try again later."
        };

        return statusMessages[status] || "An unexpected error occurred. Please try again.";
    }

    /**
     * Cleans and formats error messages
     * @param {string} message - The error message to clean
     * @returns {string} Cleaned error message
     */
    static cleanErrorMessage(message) {
        return message
            .replace(/^\[|\]$/g, '')                   // Remove brackets
            .replace(/['"]/g, '')                      // Remove quotes
            .replace(/\s+/g, ' ')                      // Normalize whitespace
            .replace(/\b(?:error|Error):\s*/g, '')     // Remove "error:" prefix
            .trim();
    }

    /**
     * Formats field names into user-friendly text
     * @param {string} fieldName - The field name to format
     * @returns {string} Formatted field name
     */
    static formatFieldName(fieldName) {
        return fieldName
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }
}

export default ErrorHandler;