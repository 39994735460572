<template>
    <div class="container mt-4">
      <!-- Row with back button and headings -->
      <div class="row align-items-center mb-4">
        <div class="col-md-3">
          <!-- Back button -->
          <button class="btn btn-primary" @click="goBack">
            <i class="bi bi-arrow-left"></i> Back To Customs Entry
          </button>
        </div>
        <div class="col-md-6">
          <h1 class="mb-0">{{userDetails.mawi_business.country.name}}</h1>
          <h1 class="mb-0">{{ formName }}</h1>
        </div>
      </div>
  
      <form @submit.prevent="generateC75Declaration" class="my-form-container">
        <CustomsDeclarationSectionOne
          :buyerIdProp="this.entryDetails?.waybill?.importer_id"
          :customsDeclarationSectionOne="form.customsDeclarationSectionOne"
          @update-section-one="form.customsDeclarationSectionOne = $event"
        />
        <CustomsDeclarationSectionTwo
          :customsDeclarationSectionTwo="form.customsDeclarationSectionTwo"
          :customsDeclarationSectionOne="form.customsDeclarationSectionOne"
          @update-section-two="form.customsDeclarationSectionTwo = $event"
        />
        <CustomsDeclarationSectionThree
          :customsDeclarationSectionThree="form.customsDeclarationSectionThree"
          @update-section-three="form.customsDeclarationSectionThree = $event"
        />
        <CustomsDeclarationSectionFour
          :customsDeclarationSectionFour="form.customsDeclarationSectionFour"
          :customsDeclarationSectionThree="form.customsDeclarationSectionThree"
          @update-section-four="form.customsDeclarationSectionFour = $event"
        />
      </form>
  
      <table>
        <tr>
          <td>
            <button class="btn btn-primary" @click="save75Declaration">Save Valuation Form</button>
          </td>
          <td>
            <button class="btn btn-primary" @click="generateValuationFormPDFFastAPI">Generate Valuation Form</button>
          </td>
          <td>
            <button class="btn btn-primary" @click="generateValuationFormPDFPreSignedFastAPI">Generate Pre-Signed Valuation Form</button>
          </td>
          <td>
            <button class="btn btn-primary" @click="generateBlanlValuationForPreSigning">Generate Blank Valuation for Pre-Signing</button>
          </td>
        </tr>
      </table>
    </div>
  </template>

<script>
import CustomsDeclarationSectionOne from './CustomsDeclarationSections/CustomsDeclarationSectionOne.vue';
import CustomsDeclarationSectionTwo from './CustomsDeclarationSections/CustomsDeclarationSectionTwo.vue';
import CustomsDeclarationSectionThree from './CustomsDeclarationSections/CustomsDeclarationSectionThree.vue';
import CustomsDeclarationSectionFour from './CustomsDeclarationSections/CustomsDeclarationSectionFour.vue';

import * as DatabaseConnector from '@/composables/DatabaseConnector';
import * as SweetAlertConnector from '../composables/SweetAlertConnector';

import * as FastAPI from '@/composables/FastAPI';
import * as Utilities from "@/composables/Utilities";
import { FAST_API_RESPONSE_STATUS_CODES } from "../constants";

export default {
    name: 'CustomsDeclarationForm',
    components: {
        CustomsDeclarationSectionOne,
        CustomsDeclarationSectionTwo,
        CustomsDeclarationSectionThree,
        CustomsDeclarationSectionFour
    },
    props: {
        entryId: {
            type: [String, Number],
            required: true
        }
    },
    
    mounted() {
        this.getUser();
        // this.fetchCustomsDeclarations();
        // this.syncData();
        this.getCurrencies();
        this.getCountries();
        // this.getEntryDetails();
        this.getAllDetails();
    },
    data() {
        return {
            newC75: true,
            valuationFormId: '',
            entryNumber: '',
            currencies: [],
            countries: [],
            entryDetails: null,
            formName: "",
            userDetails: {'mawi_business':{'country':""}},
            form: {
                customsDeclarationSectionOne: {
                  sellers: [],
                  buyers: [],
                  declarants: [],
                  sellerNameAddress: '',
                  buyerNameAddress: '',
                  declarantNameAddress: '',
                },
                customsDeclarationSectionTwo: {
                  date_signed: this.getCurrentDate(),
                  relatedParties: 'no',
                  influencePrice: 'no',
                  transactionValueApproximate: 'no',
                  restrictions: 'no',
                  conditions: 'no',
                  royalties: 'no',
                  resale_disposal_boolean: 'no',
                },
                customsDeclarationSectionThree: {
                    totalNationalCurrency : 0.0,
                    netPrice: 0.0,
                    indirectPayments: 0.0,
                    exchangeRate: 1.0,
                    transport: 0.0,
                    loadingHandling: 0.0,
                    insurance: 0.0,
                    totalB: 0.0,
                    // transportIncluded: false,
                    // insuranceIncluded: false,
                    invoiceCurrency: 'USD',
                },
                customsDeclarationSectionFour: {
                    costAfterArrival: 0.0,
                    chargesAfterImportation: 0.0,
                    otherCharges: 0.0,
                    customsDutiesTaxes: 0.0,
                    totalC: 0.0,
                    valueDeclared: 0.0
                }
            }
        }
    },

    methods: {
        goBack() {
            this.$router.go(-1); // Go back to the previous page
        },
        async getUser(){
            try{
                this.userDetails = await FastAPI.getUserDetails();
                if (this.userDetails.mawi_business.country.name == "Guyana"){
                    this.formName = "Customs Valuation Form"
                }else{
                    this.formName = "Customs Declaration Form E-C75"
                }
            }
            catch(error){
                console.log("Error getting all details", error)
            }
        },
        async getAllDetails(){
            try{
                await this.fetchCustomsDeclarations();
                console.log("Successfully loaded entry details", this.entryDetails);
                this.syncData();
                this.userDetails = await FastAPI.getUserDetails();
                console.log(this.userDetails)
            }
            catch(error){
                console.log("Error getting all details", error)
            }
        },
        async getEntryDetails(){
            try{
                const entryDetails = await FastAPI.getCustomsEntry(this.entryId);
                console.log("Successfully loaded entry details", entryDetails);
                this.entryDetails = entryDetails
            }
            catch(error){
                console.log("Error getting details", error)
            }
        },
        async save75Declaration(){
            if(this.newC75){
                await this.saveCustomsDeclarationFastAPI();
            } else {
                await this.updateC75Declaration();
            }
        },
        async getCurrencies(){
            try {
                this.currencies = await FastAPI.getCurrencies();
            }
            catch (error) {
                console.error("Error fetching currencies", error);
            }
        },
        getCountries(){
          FastAPI.getCountries().then(response => {
            this.countries = response;
          }).catch(error => {
            console.error('Failed to get countries:', error);
          });
        },
        getCurrentDate() {
          const date = new Date();
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear();

          return `${year}-${month}-${day}`;
        },
        async create75Declaration() {
            console.log('Saving C75 Declaration...');

            const selectedSeller = this.form.customsDeclarationSectionOne.sellers.find(seller => seller.id === this.form.customsDeclarationSectionOne.sellerId);
            const selectedBuyer = this.form.customsDeclarationSectionOne.buyers.find(buyer => buyer.id === this.form.customsDeclarationSectionOne.buyerId);
            const selectedDeclarant = this.form.customsDeclarationSectionOne.declarants.find(declarant => declarant.id === this.form.customsDeclarationSectionOne.declarantId);

            const sellerNameAddress = selectedSeller ? `${selectedSeller.name} \n ${selectedSeller.address}` : '';
            const buyerNameAddress = selectedBuyer ? `${selectedBuyer.name} \n ${selectedBuyer.address}` : '';
            const declarantNameAddress = selectedDeclarant ? `${selectedDeclarant.name} \n ${selectedDeclarant.address}` : '';

            const declarantStatus = selectedDeclarant ? selectedDeclarant.status : '';
            const declarantPhone = selectedDeclarant?.phone ? selectedDeclarant.phone : '';
            const declarantName = selectedDeclarant ? selectedDeclarant.name : '';
            SweetAlertConnector.showLoadingAlert('Saving Data...');
            const declarationData = {
                "sellerNameAddress": sellerNameAddress,
                "buyerNameAddress": buyerNameAddress,
                "declarantNameAddress": declarantNameAddress,
                "incoTerms": this.form.customsDeclarationSectionOne.termsOfDelivery !== undefined ? this.form.customsDeclarationSectionOne.termsOfDelivery : "",
                "invoiceNumbersDates": this.form.customsDeclarationSectionOne.invoiceNumber !== undefined ? this.form.customsDeclarationSectionOne.invoiceNumber : "",
                "numberAndDateofContract": this.form.customsDeclarationSectionOne.contractNumber !== undefined ? this.form.customsDeclarationSectionOne.contractNumber : "",
                "sellerId": this.form.customsDeclarationSectionOne.sellerId !== undefined ? this.form.customsDeclarationSectionOne.sellerId : "",
                "buyerId": this.form.customsDeclarationSectionOne.buyerId !== undefined ? this.form.customsDeclarationSectionOne.buyerId : "",
                "declarantId": this.form.customsDeclarationSectionOne.declarantId !== undefined ? this.form.customsDeclarationSectionOne.declarantId : "",
                "relatedParties": this.form.customsDeclarationSectionTwo.relatedParties !== undefined ? this.form.customsDeclarationSectionTwo.relatedParties : "",
                "influencePrice": this.form.customsDeclarationSectionTwo.influencePrice !== undefined ? this.form.customsDeclarationSectionTwo.influencePrice : "",
                "transactionValueApproximate": this.form.customsDeclarationSectionTwo.transactionValueApproximate !== undefined ? this.form.customsDeclarationSectionTwo.transactionValueApproximate : "",
                "restrictions": this.form.customsDeclarationSectionTwo.restrictions !== undefined ? this.form.customsDeclarationSectionTwo.restrictions : "",
                "conditions": this.form.customsDeclarationSectionTwo.conditions !== undefined ? this.form.customsDeclarationSectionTwo.conditions : "",
                "royalties_boolean": this.form.customsDeclarationSectionTwo.royalties !== undefined ? this.form.customsDeclarationSectionTwo.royalties : "",
                "resale_disposal_boolean": this.form.customsDeclarationSectionTwo.resale_disposal_boolean !== undefined ? this.form.customsDeclarationSectionTwo.resale_disposal_boolean : "",
                "date_signed": this.form.customsDeclarationSectionTwo.date_signed !== undefined ? this.form.customsDeclarationSectionTwo.date_signed : "",
                "status_of_signatory": declarantStatus,
                "signatory_phone_number": declarantPhone,
                "signatory_name": declarantName,
                "net_invoice_price": this.form.customsDeclarationSectionThree.netPrice !== undefined ? this.form.customsDeclarationSectionThree.netPrice : "",
                "indirect_payment": this.form.customsDeclarationSectionThree.indirectPayment !== undefined ? this.form.customsDeclarationSectionThree.indirectPayment : "",
                "exchange_rate": this.form.customsDeclarationSectionThree.exchangeRate !== undefined ? this.form.customsDeclarationSectionThree.exchangeRate : "",
                "costs_commissions": this.form.customsDeclarationSectionThree.commissions !== undefined ? this.form.customsDeclarationSectionThree.commissions : "",
                "costs_brokerage": this.form.customsDeclarationSectionThree.brokerage !== undefined ? this.form.customsDeclarationSectionThree.brokerage : "",
                "costs_containers_packing": this.form.customsDeclarationSectionThree.containersPacking !== undefined ? this.form.customsDeclarationSectionThree.containersPacking : "",
                "goods_free_of_charge_materials": this.form.customsDeclarationSectionThree.materials !== undefined ? this.form.customsDeclarationSectionThree.materials : "",
                "goods_free_of_charge_tools": this.form.customsDeclarationSectionThree.tools !== undefined ? this.form.customsDeclarationSectionThree.tools : "",
                "goods_free_of_charge_materials_consumed": this.form.customsDeclarationSectionThree.materialsConsumed !== undefined ? this.form.customsDeclarationSectionThree.materialsConsumed : "",
                "goods_engineering_development": this.form.customsDeclarationSectionThree.engineering !== undefined ? this.form.customsDeclarationSectionThree.engineering : "",
                "royalties_license_fee": this.form.customsDeclarationSectionThree.royalties !== undefined ? this.form.customsDeclarationSectionThree.royalties : "",
                "procees_resale_disposal": this.form.customsDeclarationSectionThree.proceedsResale !== undefined ? this.form.customsDeclarationSectionThree.proceedsResale : "",
                "cost_delivery_transport": this.form.customsDeclarationSectionThree.transport !== undefined ? this.form.customsDeclarationSectionThree.transport : "",
                "cost_delivery_loading": this.form.customsDeclarationSectionThree.loadingHandling !== undefined ? this.form.customsDeclarationSectionThree.loadingHandling : "",
                "cost_delivery_insurance": this.form.customsDeclarationSectionThree.insurance !== undefined ? this.form.customsDeclarationSectionThree.insurance : "",
                "cost_transport_after_arrival": this.form.customsDeclarationSectionFour.costAfterArrival !== undefined ? this.form.customsDeclarationSectionFour.costAfterArrival : "",
                "charges_construction": this.form.customsDeclarationSectionFour.chargesAfterImportation !== undefined ? this.form.customsDeclarationSectionFour.chargesAfterImportation : "",
                "other_charges": this.form.customsDeclarationSectionFour.otherCharges !== undefined ? this.form.customsDeclarationSectionFour.otherCharges : "",
                "customs_duties_taxes": this.form.customsDeclarationSectionFour.customsDutiesTaxes !== undefined ? this.form.customsDeclarationSectionFour.customsDutiesTaxes : "",
                "entryId": this.entryId
            }
            try {
                console.log('Saving customs declaration...');
                console.log(declarationData);
                await DatabaseConnector.saveCustomsDeclaration(declarationData);
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showSuccessAlert('All Data Saved successfully!');
                console.log('Customs declaration saved successfully.');
                this.newC75 = false;
            } catch (error) {
                SweetAlertConnector.showErrorAlert('Failed to save Entry Data!');
                console.error('Failed to save the declaration:', error);
            }
        },
        convertStringToBoolean(string){
            return string === 'yes' ? true : false;
        },
        prepareDeclarationObject(){
          console.log("Prearing Declaration Object...")
          
          let declarationData = {
                "name_date_contract": this.form.customsDeclarationSectionOne.contractNumber !== undefined ? this.form.customsDeclarationSectionOne.contractNumber : "",
                "related_parties_boolean": this.form.customsDeclarationSectionTwo.relatedParties !== undefined ? this.convertStringToBoolean(this.form.customsDeclarationSectionTwo.relatedParties) : false,
                "influence_price_boolean": this.form.customsDeclarationSectionTwo.influencePrice !== undefined ? this.convertStringToBoolean(this.form.customsDeclarationSectionTwo.influencePrice) : false,
                "transaction_value_approximate_boolean": this.form.customsDeclarationSectionTwo.transactionValueApproximate !== undefined ? this.convertStringToBoolean(this.form.customsDeclarationSectionTwo.transactionValueApproximate) : false,
                "restrictions_disposal_boolean": this.form.customsDeclarationSectionTwo.restrictions !== undefined ? this.convertStringToBoolean(this.form.customsDeclarationSectionTwo.restrictions) : false,
                "sale_price_condition_boolean": this.form.customsDeclarationSectionTwo.conditions !== undefined ? this.convertStringToBoolean(this.form.customsDeclarationSectionTwo.conditions) : false,
                "royalties_boolean": this.form.customsDeclarationSectionTwo.royalties !== undefined ? this.convertStringToBoolean(this.form.customsDeclarationSectionTwo.royalties) : false,
                "resale_disposal_boolean": this.form.customsDeclarationSectionTwo.resale_disposal_boolean !== undefined ? this.convertStringToBoolean(this.form.customsDeclarationSectionTwo.resale_disposal_boolean) : false,
                "date_signed": this.form.customsDeclarationSectionTwo.date_signed !== undefined ? this.form.customsDeclarationSectionTwo.date_signed : "",
                "costs_commissions": this.form.customsDeclarationSectionThree.commissions !== undefined ? this.form.customsDeclarationSectionThree.commissions : 0.00,
                "costs_brokerage": this.form.customsDeclarationSectionThree.brokerage !== undefined ? this.form.customsDeclarationSectionThree.brokerage : 0.00,
                "costs_containers_packing": this.form.customsDeclarationSectionThree.containersPacking !== undefined ? this.form.customsDeclarationSectionThree.containersPacking : 0.00,
                "goods_free_of_charge_materials": this.form.customsDeclarationSectionThree.materials !== undefined ? this.form.customsDeclarationSectionThree.materials : 0.00,
                "goods_free_of_charge_tools": this.form.customsDeclarationSectionThree.tools !== undefined ? this.form.customsDeclarationSectionThree.tools : 0.00,
                "goods_free_of_charge_materials_consumed": this.form.customsDeclarationSectionThree.materialsConsumed !== undefined ? this.form.customsDeclarationSectionThree.materialsConsumed : 0.00,
                "goods_engineering_development": this.form.customsDeclarationSectionThree.engineering !== undefined ? this.form.customsDeclarationSectionThree.engineering : 0.00,
                "royalties_license_fee": this.form.customsDeclarationSectionThree.royalties !== undefined ? this.form.customsDeclarationSectionThree.royalties : 0.00,
                "procees_resale_disposal": this.form.customsDeclarationSectionThree.proceedsResale !== undefined ? this.form.customsDeclarationSectionThree.proceedsResale : 0.00,
                "cost_delivery_loading": this.form.customsDeclarationSectionThree.loadingHandling !== undefined ? this.form.customsDeclarationSectionThree.loadingHandling : 0.00,
                "cost_transport_after_arrival": this.form.customsDeclarationSectionFour.costAfterArrival !== undefined ? this.form.customsDeclarationSectionFour.costAfterArrival : 0.00,
                "charges_construction": this.form.customsDeclarationSectionFour.chargesAfterImportation !== undefined ? this.form.customsDeclarationSectionFour.chargesAfterImportation : 0.00,
                "other_charges": this.form.customsDeclarationSectionFour.otherCharges !== undefined ? this.form.customsDeclarationSectionFour.otherCharges : 0.00,
                "customs_duties_taxes": this.form.customsDeclarationSectionFour.customsDutiesTaxes !== undefined ? this.form.customsDeclarationSectionFour.customsDutiesTaxes : 0.00,
                "id": this.valuationFormId,
                "entry_id": this.entryId
            }
            console.log(declarationData)
            return declarationData;
        },
        async generateBlanlValuationForPreSigning(){
            console.log('Generating C75 Declaration...');
            try {
                console.log('Generating customs declaration...');
                SweetAlertConnector.showLoadingAlert('Generating customs declaration...');
                const response = await DatabaseConnector.generateBlankValuationForm();
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showSuccessAlert('Valuation Form Generated Successfully!');
                const fileURL = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                const fileName = `Blank Valuation Form.pdf`;
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);

                fileLink.click();
                fileLink.remove();
            } catch (error) {
                SweetAlertConnector.showErrorAlert('Failed to generate document!');
                console.error('Failed to generate the declaration:', error);
            }
        },
        async generateC75Declaration() {
            const declarationData = this.prepareDeclarationObject();
            if(declarationData.declarantNameAddress === "" || declarationData.declarantNameAddress === undefined){
                SweetAlertConnector.showErrorAlert('Please select a declarant!');
                return;
            }
            console.log('Generating C75 Declaration...');
            try {
                console.log('Generating customs declaration...');
                SweetAlertConnector.showLoadingAlert('Generating customs declaration...');
                const response = await DatabaseConnector.generateCustomsDeclaration(declarationData);
                const referenceNumber = this.entryDetails?.entry_number || "";
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showSuccessAlert('C75 Generated Successfully!');
                const fileURL = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                const fileName = `Customs Declaration - ${referenceNumber}.pdf`;
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);

                fileLink.click();
                fileLink.remove();
            } catch (error) {
                SweetAlertConnector.showErrorAlert('Failed to save Entry Data!');
                console.error('Failed to generate the declaration:', error);
            }
        },
        async generatePreSignedValuation() {
            const declarationData = this.prepareDeclarationObject();
            console.log('Generating C75 Declaration...');
            try {
                console.log('Generating customs declaration...');
                SweetAlertConnector.showLoadingAlert('Generating customs declaration...');
                const response = await DatabaseConnector.generatePreSignedValuationForm(declarationData);
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showSuccessAlert('C75 Generated Successfully!');
                const fileURL = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                const fileName = `Customs Declaration - ${this.entryNumber}.pdf`;
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);

                fileLink.click();
                fileLink.remove();
            } catch (error) {
                SweetAlertConnector.showErrorAlert('Failed to save Entry Data!');
                console.error('Failed to generate the declaration:', error);
            }
        },
        async generatePreSignedValuationVersionTwo(){
            const declarationData = this.prepareDeclarationObject();
            console.log('Generating C75 Declaration...');
            try {
                console.log('Generating customs declaration...');
                SweetAlertConnector.showLoadingAlert('Generating customs declaration...');
                const response = await DatabaseConnector.generatePreSignedValuationFormVersion2(declarationData);
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showSuccessAlert('C75 Generated Successfully!');
                const fileURL = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                const fileName = `Customs Declaration - ${this.entryNumber}.pdf`;
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);

                fileLink.click();
                fileLink.remove();
            } catch (error) {
                SweetAlertConnector.showErrorAlert('Failed to save Entry Data!');
                console.error('Failed to generate the declaration:', error);
            }

        },
        async updateEntryDetails(){
            try {
                // Call the function to update the customs entry
                if(this.form.customsDeclarationSectionOne.declarantId !== null && this.form.customsDeclarationSectionOne.declarantId !== undefined && this.form.customsDeclarationSectionOne.declarantId != ""){
                    this.entryDetails.value_declarant_id = this.form.customsDeclarationSectionOne.declarantId || null;
                }
                const response = await FastAPI.updateCustomsEntry(this.entryDetails?.id, this.entryDetails);

                if (response.status === FAST_API_RESPONSE_STATUS_CODES.SUCCESS) {
                    console.log("Entry updated successfully:", response.data);
                    // SweetAlertConnector.showSuccessAlert("Entry details updated successfully.");
                    return response.data;
                }

                if (response.status === FAST_API_RESPONSE_STATUS_CODES.UNPROCESSABLE_ENTITY) {
                    const errorMessage = Utilities.generateErrorMessage(response.data);
                    SweetAlertConnector.showErrorAlert(errorMessage);
                    throw new Error(errorMessage);
                } else {
                    throw new Error("Failed to update entry details.");
                }
            } catch (error) {
                console.error("Failed to update entry details:", error);
                SweetAlertConnector.showErrorAlert("An error occurred while updating entry details. Please try again.");
                throw error;
            }
        },
        async generateValuationFormPDFFastAPI(){
            console.log("Generating Valuation PDF");
            try{
                await this.save75Declaration();
                await this.updateEntryDetails();
                SweetAlertConnector.showLoadingAlert("Generating Valuation Form...");
                const userCountry = this.userDetails.mawi_business.country.two_letter_code;
                if(userCountry === 'GY'){
                    await FastAPI.generateValuationFormGYPDF(this.entryId, this.valuationFormId, false, this.entryDetails?.entry_number);
                }
                else {
                    await FastAPI.generateValuationFormPDF(this.entryId, this.valuationFormId, false, this.entryDetails?.entry_number, this.form.customsDeclarationSectionOne.invoiceNumber);
                }
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showSuccessAlert("Successfully Generated Valuation Form.")
            }
            catch(error){
                console.log(error)
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showErrorAlert("Error Generating Valuation form.")
            }
        },
        async generateValuationFormPDFPreSignedFastAPI(){
            console.log("Generating Valuation PDF");
            try{
                await this.save75Declaration();
                await this.updateEntryDetails();
                SweetAlertConnector.showLoadingAlert("Generating Valuation Form...");
                await FastAPI.generateValuationFormPDF(this.entryId, this.valuationFormId, true, this.entryDetails?.entry_number, this.form.customsDeclarationSectionOne.invoiceNumber);
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showSuccessAlert("Successfully Generated Valuation Form.")
            }
            catch(error){
                console.log(error)
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showErrorAlert("Error Generating Valuation form.")
            }
        },
        async saveCustomsDeclarationFastAPI(){
            console.log('Creating valuation form record Fast API ...');
            const declarationData = this.prepareDeclarationObject();
            try {
                SweetAlertConnector.showLoadingAlert('Saving Data...');
                console.log('Creating valuation form record...');
                // await DatabaseConnector.updateCustomsDeclaration(this.entryId, declarationData);
                const valuationResponse = await FastAPI.submitValuationForm(declarationData)
                this.valuationFormId = valuationResponse.id
                await this.updateEntryDetails();
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showSuccessAlert('All Data Saved successfully!');
                console.log('Customs declaration updated successfully.');
            } catch (error) {
              SweetAlertConnector.showErrorAlert('Failed to save Entry Data!');
                console.error('Failed to update the declaration:', error);
            }
        },
        async updateC75Declaration(){
            console.log('Updating C75 Declaration...');
            const declarationData = this.prepareDeclarationObject();
            try {
                SweetAlertConnector.showLoadingAlert('Saving Data...');
                console.log('Updating customs declaration...');
                // await DatabaseConnector.updateCustomsDeclaration(this.entryId, declarationData);
                await FastAPI.updateValuationForm(declarationData)
                await this.updateEntryDetails();
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showSuccessAlert('All Data Saved successfully!');
                console.log('Customs declaration updated successfully.');
            } catch (error) {
              SweetAlertConnector.showErrorAlert('Failed to save Entry Data!');
                console.error('Failed to update the declaration:', error);
            }
        },
        async fetchCustomsDeclarations(){
            try {
                console.log('Checking for existing valuation form records...');
                const valuationFormResponse = await FastAPI.searchValuationForms(this.entryId);
                console.log('Valuation Form Response:', valuationFormResponse);
                this.newC75 = true;
                if(valuationFormResponse.length > 0){
                    this.entryDetails = valuationFormResponse[0].customs_entry;
                    console.log("Received Entry Details")
                    console.log('Valuation Form found.', valuationFormResponse[0]);
                    this.newC75 = false;
                    this.updateFields(valuationFormResponse[0]);
                    return;
                }
                else{
                    console.log('No valuation Form found.');
                    await this.getEntryDetails()
                }
            } catch (error) {
                console.error('Failed to get the C75 Entry:', error);
            }
        },
        syncData(){
            console.log('Syncing data...');
            this.form.customsDeclarationSectionOne.buyerId = parseInt(this.entryDetails?.waybill?.importer_id);
            this.form.customsDeclarationSectionOne.sellerId = parseInt(this.entryDetails?.waybill?.exporter_id);
            this.form.customsDeclarationSectionOne.declarantId = this.entryDetails?.value_declarant_id || "";
            
            
            
            
            
            this.form.customsDeclarationSectionOne.termsOfDelivery = this.entryDetails?.delivery_terms_id;

            /*Generate the invoice number String */
            let invoiceNumberString = "";
            let totalCargoValue = 0.0;
            this.entryDetails.commercial_invoices.forEach((invoice, index) => {
                invoiceNumberString += `${invoice.invoice_number} - ${invoice.invoice_date}`;
                totalCargoValue += invoice.invoice_total + invoice.inland + invoice.insurance + invoice.other_charges
                if(index < this.entryDetails.commercial_invoices.length - 1){
                    invoiceNumberString += ", ";
                }
            });

            const freightLocalCurrency = this.entryDetails?.waybill?.international_freight?.amount * this.entryDetails?.waybill?.international_freight?.rate_of_exchange.exchange_rate;
            const insuranceLocalCurrency = this.entryDetails?.waybill?.insurance?.amount * this.entryDetails?.waybill?.insurance?.rate_of_exchange.exchange_rate;
            
            if(this.entryDetails?.delivery_terms.code === 'CIF'){
                this.form.customsDeclarationSectionThree.insuranceIncluded = true;
                this.form.customsDeclarationSectionThree.transportIncluded = true;
                totalCargoValue += this.entryDetails?.waybill?.international_freight?.amount + this.entryDetails?.waybill?.insurance?.amount + this.entryDetails?.waybill?.other_charges?.amount;
            }
            else if(this.entryDetails?.delivery_terms.code === 'CFR'){
                this.form.customsDeclarationSectionThree.transportIncluded = true;
                this.form.customsDeclarationSectionThree.insuranceIncluded = false;
                this.form.customsDeclarationSectionThree.insurance = insuranceLocalCurrency;
                totalCargoValue += this.entryDetails?.waybill?.international_freight?.amount;
            }
            else if(this.entryDetails?.delivery_terms.code === 'CIP'){
                this.form.customsDeclarationSectionThree.transportIncluded = false;
                this.form.customsDeclarationSectionThree.insuranceIncluded = true;
                this.form.customsDeclarationSectionThree.transport = freightLocalCurrency;
                totalCargoValue += this.entryDetails?.waybill?.insurance?.amount;
            }
            else{
                this.form.customsDeclarationSectionThree.transportIncluded = false;
                this.form.customsDeclarationSectionThree.insuranceIncluded = false;
                this.form.customsDeclarationSectionThree.transport = freightLocalCurrency;
                this.form.customsDeclarationSectionThree.insurance = insuranceLocalCurrency;
            }

            this.form.customsDeclarationSectionOne.invoiceNumber = invoiceNumberString;
            this.form.customsDeclarationSectionThree.netPrice = totalCargoValue;
            this.form.customsDeclarationSectionThree.invoiceCurrency = this.entryDetails?.commercial_invoices[0]?.rate_of_exchange.base_currency_id;
            this.form.customsDeclarationSectionThree.exchangeRate = this.entryDetails?.commercial_invoices[0]?.rate_of_exchange.exchange_rate;

            Promise.all([FastAPI.getExporters(), FastAPI.getImporters(), FastAPI.getVessels(), FastAPI.getValueDeclarants(), FastAPI.getCountries(), FastAPI.getIncoTerms(), FastAPI.getCurrencies()]).then(([exporters, importers, vessels, declarants, countries, incotermsList, currencyList]) => { 
                this.form.customsDeclarationSectionOne.sellers = exporters;
                this.form.customsDeclarationSectionOne.buyers = importers;
                this.form.customsDeclarationSectionOne.vessels = vessels;
                this.form.customsDeclarationSectionOne.incoterms = incotermsList;
                this.form.customsDeclarationSectionOne.declarants = declarants;
                this.form.customsDeclarationSectionOne.countryList = countries;
                this.form.customsDeclarationSectionThree.currencyList = currencyList;
            });
        },
        convertTinyIntoString(tiny){
            return tiny === 1 ? 'yes' : 'no';
        },
        convertBooleanIntoString(boolean){
            return boolean ? 'yes' : 'no';
        },
        updateFields(responseData){
            console.log('Updating fields...');
            this.valuationFormId = responseData.id;
            this.form.customsDeclarationSectionOne.contractNumber = responseData.name_date_contract;
            this.form.customsDeclarationSectionTwo.relatedParties = this.convertBooleanIntoString(responseData.related_parties_boolean);
            this.form.customsDeclarationSectionTwo.influencePrice = this.convertBooleanIntoString(responseData.influence_price_boolean);
            this.form.customsDeclarationSectionTwo.transactionValueApproximate = this.convertBooleanIntoString(responseData.transaction_value_approximate_boolean);
            this.form.customsDeclarationSectionTwo.restrictions = this.convertBooleanIntoString(responseData.restrictions_disposal_boolean);
            this.form.customsDeclarationSectionTwo.conditions = this.convertBooleanIntoString(responseData.sale_price_condition_boolean);
            this.form.customsDeclarationSectionTwo.royalties = this.convertBooleanIntoString(responseData.royalties_boolean);
            this.form.customsDeclarationSectionTwo.resale_disposal_boolean = this.convertBooleanIntoString(responseData.resale_disposal_boolean);
            this.form.customsDeclarationSectionTwo.date_signed = responseData.date_signed;
            this.form.customsDeclarationSectionThree.commissions = responseData.costs_commissions;
            this.form.customsDeclarationSectionThree.brokerage = responseData.costs_brokerage;
            this.form.customsDeclarationSectionThree.containersPacking = responseData.costs_containers_packing;
            this.form.customsDeclarationSectionThree.materials = responseData.goods_free_of_charge_materials;
            this.form.customsDeclarationSectionThree.tools = responseData.goods_free_of_charge_tools;
            this.form.customsDeclarationSectionThree.materialsConsumed = responseData.goods_free_of_charge_materials_consumed;
            this.form.customsDeclarationSectionThree.engineering = responseData.goods_engineering_development;
            this.form.customsDeclarationSectionThree.royalties = responseData.royalties_license_fee;

            this.form.customsDeclarationSectionThree.proceedsResale = responseData.procees_resale_disposal;
            
            // this.form.customsDeclarationSectionThree.transport = freightLocalCurrency;
            this.form.customsDeclarationSectionThree.loadingHandling = responseData.cost_delivery_loading;
            
            // this.form.customsDeclarationSectionThree.insurance = insuranceLocalCurrency;
            this.form.customsDeclarationSectionFour.costAfterArrival = responseData.cost_transport_after_arrival;
            this.form.customsDeclarationSectionFour.chargesAfterImportation = responseData.charges_construction;
            this.form.customsDeclarationSectionFour.otherCharges = responseData.other_charges;

            this.form.customsDeclarationSectionFour.customsDutiesTaxes = responseData.customs_duties_taxes;

            const freightLocalCurrency = responseData.customs_entry.waybill.international_freight.amount * responseData.customs_entry.waybill.international_freight.rate_of_exchange.exchange_rate;
            const insuranceLocalCurrency = responseData.customs_entry.waybill.insurance.amount * responseData.customs_entry.waybill.insurance.rate_of_exchange.exchange_rate;
            const incoTerms = responseData.customs_entry.delivery_terms.code;

            if(incoTerms === 'CIF'){
                this.form.customsDeclarationSectionThree.transportIncluded = true;
                this.form.customsDeclarationSectionThree.insuranceIncluded = true;
                this.form.customsDeclarationSectionThree.transport = 0.0;
                this.form.customsDeclarationSectionThree.insurance = 0.0;
            }
            else if(incoTerms === 'CFR'){
                this.form.customsDeclarationSectionThree.transportIncluded = true;
                this.form.customsDeclarationSectionThree.insuranceIncluded = false;
                this.form.customsDeclarationSectionThree.transport = 0.0;
                this.form.customsDeclarationSectionThree.insurance = insuranceLocalCurrency;
            }
            else if(incoTerms === 'CIP'){
                console.log("FOUND CIP")
                this.form.customsDeclarationSectionThree.transportIncluded = false;
                this.form.customsDeclarationSectionThree.insuranceIncluded = true;
                this.form.customsDeclarationSectionThree.transport = freightLocalCurrency;
                this.form.customsDeclarationSectionThree.insurance = 0.0;
                console.log("SAMPLE", this.form.customsDeclarationSectionThree)
            }
            else{
                this.form.customsDeclarationSectionThree.transportIncluded = false;
                this.form.customsDeclarationSectionThree.insuranceIncluded = false;
                this.form.customsDeclarationSectionThree.transport = freightLocalCurrency;
                this.form.customsDeclarationSectionThree.insurance = insuranceLocalCurrency;
            }
            console.log(this.form.customsDeclarationSectionThree)

            


        }
    }
}
</script>

<style scoped>
.my-form-container {
  text-align: left;
}
</style>
