<template>
    <div class="declarants-page">
      <h1>Declarants List</h1>
      <form class="my-form-container" @submit.prevent="addDeclarant">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="declarantName"><strong>Name</strong></label>
              <input required type="text" class="form-control" id="declarantName" placeholder="Please Enter Declarant Name" v-model="declarantName" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="declarantAddressLine1"><strong>Address Line</strong></label>
              <input type="text" class="form-control" required id="declarantAddressLine1" placeholder="Please Enter Address Line" v-model="declarantAddressLine1" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="declarantAddressLine2"><strong>Address Line 2</strong></label>
              <input type="text" class="form-control" id="declarantAddressLine2" placeholder="Please Enter Address Line 2" v-model="declarantAddressLine2" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="declarantCity"><strong>City</strong></label>
              <input type="text" class="form-control" id="declarantCity" placeholder="Please Enter City" v-model="declarantCity" />
            </div>
          </div>
          
         
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="declarantCountry"><strong>Country</strong></label>
              <v-select 
                v-model="declarantCountry"
                :options="countries"
                label="name"
                required
                placeholder="Select Country"
                :searchable="true"
                :reduce="country => country.id"
                :filterable="true"
                ></v-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="declarantStatus"><strong>Status</strong></label>
              <input type="text" class="form-control" id="declarantStatus" placeholder="Please Enter Status" v-model="declarantStatus" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="declarantImporter"><strong>Importer</strong></label>
              <v-select
                v-model="declarantImporter"
                :options="importers"
                label="name"
                placeholder="Select Importer"
                :searchable="true"
                :reduce="importer => importer.id"
                :filterable="true"
                ></v-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <p><strong></strong></p>
              <button class="btn btn-primary" type="submit">Add New Declarant</button>
            </div>
          </div>
        </div>
      </form>
      <table class="table table-striped">
        <thead class="sticky-header">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Address </th>
            <th scope="col">Country</th>
            <th scope="col">Status</th>
            <th scope="col">Expand</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(declarant, index) in declarants" :key="index">
            <tr>
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ declarant.name }}</td>
              <td>{{ declarant.address }}</td>
              <td>{{ getCountryName(declarant.country) }}</td>
              <td>{{ declarant.status }}</td>
              <td>
                <button type="button" class="btn btn-primary" @click="toggleDetails(index)">
                  <span v-if="expandedIndex === index">
                      <i class="fas fa-chevron-up" style="color: white"></i>
                  </span>
                  <span v-else>
                      <i class="fas fa-chevron-down" style="color: white"></i>
                  </span>
                </button>
              </td>
          </tr>
          <tr v-if="expandedIndex === index">
            <td colspan="6">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="declarantName"><strong>Name</strong></label>
                    <input type="text" class="form-control" id="declarantName" placeholder="Please Enter Declarant Name" v-model="declarant.name" />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="status"><strong>Status</strong></label>
                    <input type="text" class="form-control" id="status" placeholder="Please Enter Status" v-model="declarant.status" />
                  </div>
                </div> 
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="importer"><strong>Importer</strong></label>
                    <v-select
                      v-model="declarant.importer_id"
                      :options="importers"
                      label="name"
                      placeholder="Select Importer"
                      :searchable="true"
                      :reduce="importer => importer.id"
                      :filterable="true"
                      ></v-select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="declarantAddressLine1"><strong>Address Line 1</strong></label>
                    <textarea type="text" class="form-control" id="declarantAddressLine1" placeholder="Please Enter Address Line 1" v-model="declarant.address" rows="4"></textarea>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="country"><strong>Country</strong></label>
                    <v-select
                      v-model="declarant.country"
                      :options="countries"
                      label="name"
                      placeholder="Select Country"
                      :searchable="true"
                      :reduce="country => country.id"
                      :filterable="true"
                      ></v-select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <button class="btn btn-primary edit-form-button" @click="updateDeclarant(declarant)">Update Declarant</button>
                    <button class="btn btn-danger edit-form-button" @click="deleteDeclarant(declarant)">Delete Buyer</button>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          </template>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import * as DatabaseConnector from '@/composables/DatabaseConnector';
  import * as SweetAlertConnector from '@/composables/SweetAlertConnector';

  import * as FastAPI from '@/composables/FastAPI';

  export default {
    name: 'ValueDeclarants',
    data() {
      return {
        declarants: [],
        countries: [],
        importers: [],
        declarantName: '',
        declarantAddressLine1: '',
        declarantAddressLine2: '',
        declarantCity: '',
        declarantCountry: '',
        declarantStatus: '',
        declarantImporter: '',
        expandedIndex: null,
        
      };
    },
    methods: {
      toggleDetails(index) {
        this.expandedIndex = this.expandedIndex === index ? null : index;
      },
      async getDeclarants() {
        this.declarants = await FastAPI.getValueDeclarants();
        console.log("Declarants", this.declarants);
      },
      async getContries() {
        this.countries = await FastAPI.getCountries();
      },
      getCountryName(id){
        const country = this.countries.find(country => country.id === id);
        return country ? country.name : '';
      },
      async fetchImporters(){
        this.importers = await FastAPI.getImporters();
      },

      async addDeclarant() {
        if (!this.declarantName || !this.declarantAddressLine1 || !this.declarantCountry) {
          SweetAlertConnector.showErrorAlert('Please Fill in the Name, First Address and Country');
          return;
        }
        const newDeclarant = {
          name: this.declarantName,
          address: `${this.declarantAddressLine1}\n${this.declarantAddressLine2}`,
          city: this.declarantCity,
          country: this.declarantCountry,
          status: this.declarantStatus,
          importerId: this.declarantImporter,
        };
        SweetAlertConnector.showLoadingAlert('Adding Declarant...');
        try {
            const newDeclarantResponse = await FastAPI.createPartner(newDeclarant);
            console.log('Partner Created. New Declarant', newDeclarantResponse);
            const declarant = {
                partner_id: newDeclarantResponse.id,
            };
            console.log('Setting Declarant', declarant);
            await FastAPI.addValueDeclarant(declarant);
            SweetAlertConnector.showSuccessAlert('Declarant Added Successfully');
            this.getDeclarants();
            this.declarantName = '';
            this.declarantAddressLine1 = '';
            this.declarantAddressLine2 = '';
            this.declarantCity = '';
            this.declarantCountry = '';
            this.tinNumber = '';
        }
        catch (error) {
          console.error("Error adding declarant", error);
          SweetAlertConnector.showErrorAlert('Failed to Add Declarant', error);
        }
      },
      updateDeclarant(declarant) {
        const updatedDeclarant = {
            ...declarant,
            tax_identification_number: declarant.taxIdentificationNumber
        };
        console.log("Updated Declarant", updatedDeclarant);
        const valueDeclarantUpdate = {
            partner_id: declarant.partner_id,
            status: declarant.status,
            importer_id: declarant.importer_id,
        };
        console.log("Value Declarant Update", valueDeclarantUpdate);
        SweetAlertConnector.showLoadingAlert('Updating Declarant...');
        let promises = [FastAPI.updatePartner(declarant.partner_id, updatedDeclarant), FastAPI.updateValueDeclarant(updatedDeclarant.id, valueDeclarantUpdate)];
        Promise.all(promises).then(() => {
            this.getDeclarants();
            SweetAlertConnector.showSuccessAlert('Declarant updated successfully');
        }).catch((error) => {
            console.error("Error updating declarant", error);
            SweetAlertConnector.showErrorAlert('Error updating declarant', error);
        });
      },
      deleteDeclarant(declarant) {
        declarant.active = 0;
        SweetAlertConnector.showLoadingAlert('Deleting Declarant...');
        DatabaseConnector.updateDeclarant(declarant).then(() => {
          SweetAlertConnector.showSuccessAlert('Declarant Deleted Successfully');
          this.getDeclarants();
        }).catch((error) => {
          SweetAlertConnector.showErrorAlert('Failed to Delete Declarant', error);
        });
      },
    },
    mounted() {
      this.getDeclarants();
      this.getContries();
      this.fetchImporters();
    }
  }
  </script>

  
  
  <style scoped>
  .declarants-page {
    padding: 20px;
    text-align: center;
  }
  
  .customs-declaration-section-two {
    /* Your styles here */
  }
  .form-group {
    margin-bottom: 20px; /* Adds space below each form group */
  }
  .form-check {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px; /* Adds space below each form group */
  }
  .form-check label {
    margin-right: 1rem;
  }
  .my-form-container {
    text-align: left;
  }

  table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

th {
  background-color: #f4f4f8;
  color: #333;
}

button {
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
.edit-form-button {
    margin-right: 10px;
  }
  </style>
  